import React from 'react';
import Moment from 'moment';
import 'moment/dist/locale/ru';
import {extendMoment} from 'moment-range';
import {
    Panel,
    PanelHeader,
    PanelHeaderBack,
    FormItem,
    FormLayoutGroup,
    Group,
    CustomSelect,
    SelectMimicry,
    Div,
    VKCOM,
    usePlatform,
    HorizontalScroll,
    CardGrid,
    Card,
    Button,
    Snackbar,
    Avatar,
    ModalPageHeader,
    Gallery,
    Counter,
    Spacing
} from '@vkontakte/vkui';
import {useRouter} from '@happysanta/router';

import {ContextState} from "../../ContextState";
import "./style.scss";
import {Icon20InfoCircleOutline, Icon16Done} from '@vkontakte/icons';
import {PAGE_BOOKING_HOME, PAGE_BOOKING_VERIFY} from "./routes";
import dataProvider from "../../data-provider";


const BookingCreate = ({id, showModal}) => {
    const {
        dispatch,
        state: {
            user,
            studSpaces = [],
            refreshBookings,
            bookingRecord = {},
            equipmentsRegistry = []
        }
    } = React.useContext(ContextState);

    const [selectedDate, setSelectedDate] = React.useState(bookingRecord.selectedDate);
    const [startTime, setStartTime] = React.useState(bookingRecord.startTime);
    const [endTime, setEndTime] = React.useState(bookingRecord.endTime);
    const [selectedPlace, setSelectedPlace] = React.useState(bookingRecord.selectedPlace);

    const [activePanel, setActivePanel] = React.useState(id)
    const [snackbar, setSnackbar] = React.useState(null);
    const [bookButtonLoading, setBookButtonLoading] = React.useState(null);


    const {
        profile = {}, university: {
            id: universityId,
            visitingStartHour,
            visitingEndHour,
            workingStartDay,
            workingEndDay
        }
    } = user;

    React.useEffect(() => {
        let isMounted = true;

        dataProvider('/api')
            .getList('stud-space')
            .then((json) => {

                isMounted && dispatch({
                    type: 'setState',
                    payload: {
                        studSpaces: json.data
                    }
                });
            });
        return () => {
            isMounted = false;
        }
    }, [universityId]);

    React.useEffect(() => {
        if (Object.keys(equipmentsRegistry).length) {
            return;
        }

        let isMounted = true;

        dataProvider('/api')
            .getList('equipments')
            .then((json) => {

                isMounted && dispatch({
                    type: 'setState',
                    payload: {
                        equipmentsRegistry:  Object.fromEntries(json.data.map(o => [o.id, o.name]))
                    }
                });
            });
        return () => {
            isMounted = false;
        }
    }, []);

    const moment = extendMoment(Moment);
    moment.locale('ru');
    const router = useRouter();
    const platform = usePlatform();



    const timeStartSelectOptions = Array.from({length: visitingEndHour - visitingStartHour + 1}, (v, k) => k + visitingStartHour).map((v) => {
        const time = ('0' + v + ':00').substr(-5);
        return {
            label: time, value: time,
        };
    });

    const timeEndSelectOptions = startTime?timeStartSelectOptions.filter((v) => parseInt(v.value) > parseInt(startTime)):[]




    const onBookButtonClick = () => {

        if (!profile || profile && !user.verified) {
            dispatch({
                type: 'setState',
                payload: {
                    bookingRecord: {
                        selectedPlace,
                        selectedDate,
                        startTime,
                        endTime
                    }
                }
            })
            router.pushPage(PAGE_BOOKING_VERIFY);
        } else {
            setBookButtonLoading(true)
            dataProvider('/api').create('booking', {
                data: {
                    date: selectedDate,
                    space: selectedPlace.id,
                    startTime,
                    endTime
                }
            })
                .then(() => {
                    dispatch({
                        type: 'setState',
                        payload: {
                            refreshBookings: refreshBookings + 1
                        }
                    })
                    setSnackbar(
                        <Snackbar
                            onClose={() => {
                                setSnackbar(null);
                                router.pushPage(PAGE_BOOKING_HOME)
                            }}
                            before={
                                <Avatar
                                    size={24}
                                    style={{background: "var(--vkui--color_background_accent)"}}
                                >
                                    <Icon16Done fill="#fff" width={14} height={14}/>
                                </Avatar>
                            }
                        >
                            <p>Успешно забронировано.</p>
                            {user.status == 0 &&
                                <p>Твои данные были отправлены куратору Студ.Пространства на модерацию. Как только мы проверим их, тебе придет оповещение.</p>}
                        </Snackbar>
                    );

                })
                .finally(() => {
                    setBookButtonLoading(true)
                })
        }

    }


    const showStudSpaceDetails = (studSpace) => {

        const {gallery, space, equipments} = studSpace;

        showModal(<Div className="stud-space__modal">
            {gallery.length > 0 && <Gallery slideWidth="90%">{gallery.map((item, idx) => <img
                key={idx}
                src={`${item.previewUri}?size=600`}
                style={{display: "block", maxWidth: '100%', height: 'auto'}}
                width={item.width}
                height={item.height}
            />)}</Gallery>}
            <Spacing/>
            <div className="equipments">{equipments.map((item, idx) => <Counter size="s" mode="secondary" key={idx}>{equipmentsRegistry[item.equipment.id]} ({item.count} шт.)</Counter>)}</div>
            <Spacing/>
            <div style={{display: 'flex', justifyContent: 'center'}}><Button align="center" onClick={() => {
                setActivePanel(id);
                setSelectedPlace(studSpace)
                showModal(undefined, {id: 'stud-space-modal'});
            }}>Забронировать это пространство</Button></div>
        </Div>, {
            size: "l",
            id: 'stud-space-modal',
            header: <ModalPageHeader>{space.name}</ModalPageHeader>,
            dynamicContentHeight: true,
        })
    }

    return (activePanel === id) ? <Panel id={id}>
        <PanelHeader separator={false}
                     before={<PanelHeaderBack
                         onClick={() => router.pushPage(PAGE_BOOKING_HOME)}
                         label={platform === VKCOM ? "Назад" : undefined}
                     />}
        >
            Дата посещения
        </PanelHeader>

        <Div>
            <HorizontalScroll>
                <div className="date-select">
                    {Array.from(moment.range(moment(), moment().add(30, 'd'))
                        .by('day'))
                        .filter((d) => parseInt(d.format('E')) >= workingStartDay && parseInt(d.format('E')) <= workingEndDay)
                        .map((d, i) => <label className="date-select__item" key={"date-" + i}>
                            <input type="radio" name="date"
                                   checked={selectedDate === d.format('YYYY-MM-DD')}
                                   value={d.format('YYYY-MM-DD')}
                                   onChange={(ev) => setSelectedDate(ev.currentTarget.value)}/>
                            <div className="date-select__item-inner">
                                <span>{d.format('DD')}</span>
                                <span>{d.format('MMM')}</span>
                                <span>{d.format('ddd')}</span>
                            </div>
                        </label>)}
                </div>
            </HorizontalScroll>
        </Div>

        <Group header={<h5 className="group-header">Время и рабочее место</h5>}>
            <Div>
                <FormLayoutGroup mode="horizontal" before="Время работы">
                    <FormItem top="От">
                        <CustomSelect
                            value={startTime}
                            onChange={(ev) => setStartTime(ev.currentTarget.value)}
                            placeholder="Не выбран"
                            multiple={true}
                            options={timeStartSelectOptions}
                        />

                    </FormItem>
                    <FormItem top="До">
                        <CustomSelect
                            value={endTime}
                            onChange={(e) => setEndTime(e.currentTarget.value)}
                            placeholder="Не выбран"
                            multiple={true}
                            options={timeEndSelectOptions}
                        />
                    </FormItem>
                </FormLayoutGroup>

                <FormItem top="Пространство">
                    <SelectMimicry
                        placeholder="Не выбрана"
                        onClick={() => {
                            setActivePanel('studSpaces');
                        }}
                    >
                        {selectedPlace && selectedPlace.space.name}
                    </SelectMimicry>
                </FormItem>
            </Div>
        </Group>
        {selectedDate && startTime && endTime && selectedPlace && <Div>
            <CardGrid size="l">
                <Card mode="shadow" className="selected-card">
                    <div className="card-content card-content__left">
                        <span className="card-content__date">{moment(selectedDate).format('DD MMMM')} ({moment(selectedDate).format('ddd')})</span>
                        <span className="card-content__time">{startTime} - {endTime}</span>
                        <span className="card-content__place">{selectedPlace.space.name} </span>
                    </div>
                    <div className="card-content card-content__right">
                        <Button className="btn-start" loading={bookButtonLoading}
                                onClick={onBookButtonClick}>Забронировать</Button>
                    </div>
                </Card>
            </CardGrid>
        </Div>}
        {snackbar}
    </Panel> : <Panel id={id}>
        <PanelHeader separator={false}
                     before={<PanelHeaderBack
                         onClick={() => setActivePanel(id)}
                         label={platform === VKCOM ? "Назад" : undefined}
                     />}
        >
            Пространство
        </PanelHeader>

        <CardGrid size="l" spaced={true} className="CardGrid__space-select">
            {studSpaces.map((studSpace, i) => <Card key={"index-" + i}>
                {studSpace.gallery.length?<div className="space-image">
                    <img src={studSpace.gallery[0].previewUri}
                         width={studSpace.gallery[0].width}
                         height={studSpace.gallery[0].height}/>
                </div>:(studSpace.space?.photo ? <div className="space-image">
                    <img src={studSpace.space.photo.previewUri}
                         width={studSpace.space.photo.width}
                         height={studSpace.space.photo.height}/>
                </div> : null)}
                <div className="space-body">
                    <h5 className="name">{studSpace.space.name}</h5>
                    {studSpace.space.subtitle && <p>{studSpace.space.subtitle}</p>}
                    <p>Максимальное количество мест: {studSpace.capacity}</p>
                    <Button onClick={() => {
                        setActivePanel(id);
                        setSelectedPlace(studSpace)
                    }}>Выбрать</Button>
                </div>
                <div className="space-end">
                    <Icon20InfoCircleOutline onClick={() => showStudSpaceDetails(studSpace)}/>
                </div>
            </Card>)}
        </CardGrid>
    </Panel>
};


export default BookingCreate;
