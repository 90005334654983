import React, {useState} from 'react';

import {
    Panel,
    PanelHeader,
    PanelHeaderBack,
    SegmentedControl,
    Group,
    Button,
    Div,
    VKCOM,
    usePlatform, Card, CardGrid, Spinner, ModalPageHeader, Gallery, Spacing, Counter
} from '@vkontakte/vkui';
import {useParams, useRouter} from '@happysanta/router';
import QrScanner from 'qr-scanner'
import "./style.scss";

import { PAGE_BOOKING_HOME} from "./routes";
import dataProvider from "../../data-provider";
import Progress from "../../utils/progress";
import Moment from "moment/moment";
import {extendMoment} from "moment-range";
import {ContextState} from "../../ContextState";
import {Icon20InfoCircleOutline} from "@vkontakte/icons";
import {useStudSpaces} from "../../hooks/useStudSpaces";


const BookingStart = ({id, showModal}) => {

    const moment = extendMoment(Moment);
    moment.locale('ru');

    const {dispatch, state: {user, equipmentsRegistry}} = React.useContext(ContextState);

    const [step, setStep] = React.useState('loading')
    const [stepsPassed, setStepPassed] = React.useState([])
    const [formData, setFormData] = React.useState({})
    const [photoBtnLoading, setPhotoBtnLoading] = React.useState(false)
    const [abortController, setAbortController] = React.useState();
    const [percent, setPercent] = React.useState(0);
    const [bookRecord, setBookRecord] = React.useState(null);
    const [bookTimer, setBookTimer] = React.useState('00:00:00');
    const [bookEndButtonLoading, setBookEndButtonLoading] = React.useState(false);
    const [qrError, setQrError] = React.useState('');
    const [qrScanning, setQrScanning] = React.useState(false);
    const [qrScanner, setQrScanner] = React.useState();

    const isComponentMounted = React.useRef(true);

    const router = useRouter();
    const {id: bookId} = useParams();
    const {loading, data: studSpaces = [], findStudSpaces} = useStudSpaces(user, isComponentMounted);
    const platform = usePlatform();
    const qrRef = React.useRef();
    const {university} = user;

    let isMounted = true;

    React.useEffect(() => {
        const _controller = new AbortController();
        setAbortController(_controller);

        dataProvider()
            .getOne('booking', {
                id: bookId,
                parameters: {signal: _controller.signal}
            })
            .then(({data}) => {
                setBookRecord(data);
                if (data.status === 3 || data.status === 4) {
                    setStepPassed([...stepsPassed, 'step-3'])
                    setStep('end')
                } else {
                    setStep('step-1');
                }
            })

        return () => {
            //bookingTimer && clearTimeout(bookingTimer);
            isMounted = false;
            abortController && abortController.abort();
            qrScanner && qrScanner.destroy();
        }
    }, []);

    React.useEffect(() => {
        if (Object.keys(equipmentsRegistry).length) {
            return;
        }

        let isMounted = true;

        dataProvider('/api')
            .getList('equipments')
            .then((json) => {

                isMounted && dispatch({
                    type: 'setState',
                    payload: {
                        equipmentsRegistry:  Object.fromEntries(json.data.map(o => [o.id, o.name]))
                    }
                });
            });
        return () => {
            isMounted = false;
        }
    }, []);


    React.useEffect(() => {
        let timer;
        if (bookRecord && bookRecord.status === 3) {
            const _diffTotal = Moment(bookRecord.date + ' ' + moment(bookRecord.endTime).format('HH:mm')).diff(Moment(bookRecord.date + ' ' + moment(bookRecord.startTime).format('HH:mm')));
            const _diff = Moment(bookRecord.date + ' ' + moment(bookRecord.endTime).format('HH:mm')).diff(Moment());
            if (_diff > 0) {
                timer = setTimeout(function () {
                    setBookTimer(moment.utc(_diff).format("HH:mm:ss"));
                    setPercent(100 - parseInt(_diff / _diffTotal * 100))
                }, 1000);

            } else {
                dataProvider()
                    .update('booking', {
                        id: bookRecord.id,
                        data: {
                            'status': 4,
                            'actualEndedTime': Moment().format('YYYY-MM-DD HH:mm:ss')
                        }
                    })
                    .then(({data}) => {
                        setBookRecord(data);
                        setStepPassed(stepsPassed.concat(['step-3']))
                        setStep('end')

                        dispatch({
                            type: 'setState',
                            payload: {
                                refreshBookings: true
                            }
                        })
                    })
            }
        }

        return () => {
            timer && clearTimeout(timer);
        };
    }, [bookRecord, bookTimer])


    const openCodeReader = () => {
        const scanner = new QrScanner(
            qrRef.current,
            result => {
                setQrError('');
                const testUrl = 'https://vk.com/';
                const {data: qrUrl} = result;
                if (qrUrl.substring(0, testUrl.length) !== testUrl) {
                    setQrError('QR Код не распознан' + JSON.stringify(result))
                    return;
                }
                const studSpaceMatch = qrUrl.match(/#\/s(\d)+$/);
                if (!studSpaceMatch) {
                    setQrError('QR Код не распознан' + JSON.stringify(result))
                    return;
                }

                if (parseInt(studSpaceMatch[1]) !== parseInt(formData.space)) {
                    setQrError('QR код не совпадает, возможно вы ошиблись помещением, найдите {title}'.replace('{title}', findStudSpaces('id', parseInt(formData.space))[0].space.name))
                    return;
                }

                setFormData({...formData, 'qrContent': result.data});

                scanner.stop();
                scanner.destroy();
                setQrScanning(false);
                setStepPassed(stepsPassed.concat(['step-2']))
                setStep('step-3')

            },
            {
                highlightScanRegion: true,
                //highlightCodeOutline: true
                //qrEngine: '/qr/qr-scanner-worker.min.js'
            },
        );
        setQrScanner(scanner);
        scanner.start();
        setQrScanning(true);
    }

    const onPhotoSelect = (ev) => {
        const [file] = ev.currentTarget.files;
        if (file && file.type.match(/^image/)) {
            setFormData({...formData, 'photo': file});

            setPhotoBtnLoading(true);

            dataProvider()
                .update('booking', {
                    id: bookId,
                    data: {
                        ...formData,
                        'photo': file,
                        'status': 3,
                        'actualStartTime': Moment().format('YYYY-MM-DD HH:mm:ss')
                    }
                })
                .then(({data}) => {
                    setBookRecord(data);
                    setStepPassed(stepsPassed.concat(['step-3']))
                    setStep('end')
                })
                .finally(() => {
                    setPhotoBtnLoading(false);
                })
        }

    }


    const onBookEndClick = () => {
        setBookEndButtonLoading(true);
        dataProvider()
            .update('booking', {
                id: bookRecord.id,
                data: {
                    'status': 4,
                    'actualEndedTime': Moment().format('YYYY-MM-DD HH:mm:ss')
                }
            })
            .then(({data}) => {
                setBookRecord(data);
                setStep('end')
                dispatch({
                    type: 'setState',
                    payload: {
                        refreshBookings: true
                    }
                })
            })
            .catch(() => {
                setBookEndButtonLoading(false);
            })
    }

    const onStudSpaceSelect = (ev) => {
        setFormData({...formData, 'space': ev.currentTarget.dataset.id});
        setStepPassed(stepsPassed.concat(['step-1']))
        setStep('step-2')
    }

    const showStudSpaceDetails = ({id, gallery, space, equipments}) => {

        showModal(<Div className="stud-space__modal">
            {gallery.length > 0 && <Gallery slideWidth="90%">{gallery.map((item, idx) => <img
                key={idx}
                src={`${item.previewUri}?size=600`}
                style={{display: "block", maxWidth: '100%', height: 'auto'}}
                width={item.width}
                height={item.height}
            />)}</Gallery>}
            <Spacing/>
            <div className="equipments">{equipments.map((item, idx) => <Counter size="s" mode="secondary" key={idx}>{equipmentsRegistry[item.equipment.id]} ({item.count} шт.)</Counter>)}</div>
            <Spacing/>
            <div style={{display: 'flex', justifyContent: 'center'}}><Button align="center" onClick={() => {

                setFormData({...formData, 'space': id});
                setStepPassed(stepsPassed.concat(['step-1']))
                setStep('step-2')

                showModal(undefined, {id: 'stud-space-modal'});

            }}>Забронировать это пространство</Button></div>
        </Div>, {
            size: "l",
            id: 'stud-space-modal',
            header: <ModalPageHeader>{space.name}</ModalPageHeader>,
            dynamicContentHeight: true,
        })
    }

    return <Panel id={id}>
        <PanelHeader separator={false}
                     before={
                         <PanelHeaderBack
                             onClick={() => router.pushPage(PAGE_BOOKING_HOME)}
                             label={platform === VKCOM ? "Назад" : undefined}
                         />
                     }
        >
            {step !== 'end' && <>Итак, приступим!</>}
        </PanelHeader>
        {step !== 'end' && step !== 'loading' && <Div>
            <SegmentedControl options={[
                {
                    label: "Шаг 1",
                    value: "step-1",
                },
                {
                    label: "Шаг 2",
                    value: "step-2",
                },
                {
                    label: "Шаг 3",
                    value: "step-3",
                },
            ]} value={step} onChange={(v) => {
                stepsPassed.indexOf(v) > -1 && setStep(v);
            }}
            />
        </Div>}
        {step === 'loading' && <Spinner size='large'/>}

        {step === 'step-1' && <Group header={<h5 className="group-header">Выбери студпространство</h5>} id="step-1__content">
            <Div className="scheme-wrap">
                <CardGrid size="l" spaced={true} className="CardGrid__space-select">
                    {studSpaces.map((studSpace, i) => <Card key={"index-" + i}>
                        {studSpace.gallery.length?<div className="space-image">
                            <img src={studSpace.gallery[0].previewUri}
                                 width={studSpace.gallery[0].width}
                                 height={studSpace.gallery[0].height}/>
                        </div>:(studSpace.space?.photo ? <div className="space-image">
                            <img src={studSpace.space.photo.previewUri}
                                 width={studSpace.space.photo.width}
                                 height={studSpace.space.photo.height}/>
                        </div> : null)}
                        <div className="space-body">
                            <h5 className="name">{studSpace.space.name}</h5>
                            {studSpace.space.subtitle && <p>{studSpace.space.subtitle}</p>}
                            <p>Максимальное количество мест: {studSpace.capacity}</p>
                            <Button data-id={studSpace.id} onClick={onStudSpaceSelect}>Выбрать</Button>
                        </div>
                        <div className="space-end">
                            <Icon20InfoCircleOutline onClick={() => showStudSpaceDetails(studSpace)}/>
                        </div>
                    </Card>)}
                </CardGrid>
            </Div>
        </Group>}

        {step === 'step-2' && <Group>
            {qrError.length > 0 && <Div className="qr-error"><p>{qrError}</p></Div>}
            <Div>
                <video id="qr-video" style={{
                    opacity: !qrScanning ? 0 : 1
                }} playsInline ref={qrRef}></video>
            </Div>
            <Div>
                <Button size="l" stretched onClick={openCodeReader}>Доступ к камере</Button>
            </Div>
        </Group>}

        {step === 'step-3' && <Group header={<h5 className="group-header">Сфотографируй место целиком</h5>}>
            {!!formData.photo && <Div className="photo-preview"><img src={URL.createObjectURL(formData.photo)}/></Div>}
            <Div>
                <Button size="l" stretched className="btn__photo-select" loading={photoBtnLoading}>
                    Фото
                    <input type="file" onChange={onPhotoSelect} accept="image/*"/>
                </Button>
            </Div>
        </Group>}

        {step === 'end' && bookRecord && bookRecord.status === 3 &&
            <Group header={<h5 className="group-header">Бронь активирована!</h5>}>
                <Div>
                    <CardGrid size="l">
                        <Card mode="shadow" className="Card-end">
                            <Progress percent={percent}/>

                            <div>
                                До конца брони осталось<br/>
                                <time>{bookTimer}</time>
                            </div>
                        </Card>
                    </CardGrid>
                </Div>
                <Div>
                    <Button size="l" onClick={() => {
                        showModal(<Div>{university.descriptionRules}</Div>, {
                            id: 'rules-modal',
                            header: <ModalPageHeader>Правила посещения</ModalPageHeader>,
                            dynamicContentHeight: true
                        })
                    }} stretched>Правила посещения</Button>
                </Div>
                <Div>
                    <Button size="l" loading={bookEndButtonLoading} onClick={onBookEndClick} stretched>Закончить бронь</Button>
                </Div>
            </Group>}
        {step === 'end' && bookRecord && bookRecord.status === 4 &&
            <Group header={<h5 className="group-header">Бронь завершена!</h5>}>
                <Div>
                    <Button size="l" onClick={() => {
                        showModal(<Div>{university.descriptionRules}</Div>, {
                            id: 'rules-modal',
                            header: <ModalPageHeader>Правила посещения</ModalPageHeader>,
                            dynamicContentHeight: true
                        })
                    }} stretched>Правила посещения</Button>
                </Div>
                <Div>
                    <Button size="l" onClick={() => router.pushPage(PAGE_BOOKING_HOME)} stretched>Мои брони</Button>
                </Div>
            </Group>}

    </Panel>
};


export default BookingStart;
