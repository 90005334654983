import {Page} from "@happysanta/router";

export const PAGE_PROFILE = '/profile';
export const PANEL_PROFILE= 'panel_profile';

export const PAGE_PROFILE_BUG_REPORT = '/profile/bug-report';
export const PANEL_PROFILE_BUG_REPORT= 'panel_profile_bug_report';


const VIEW_EPIC = 'view_epic';

export const routes = {
    [PAGE_PROFILE]: new Page(PANEL_PROFILE, VIEW_EPIC),
    [PAGE_PROFILE_BUG_REPORT]: new Page(PANEL_PROFILE_BUG_REPORT, VIEW_EPIC),

};