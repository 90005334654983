import React from 'react';

import {
    Panel, PanelHeader, Div, Button,
    Snackbar,
    FormLayout,
    FormItem,
    Textarea,
    Input, Platform, Spinner,
    Checkbox, PanelHeaderBack, usePlatform, File as FileInput, Avatar, ButtonGroup, Alert
} from '@vkontakte/vkui';

import {ChipsSelect} from "@vkontakte/vkui/dist/unstable";

import {useRouter} from '@happysanta/router';
import dataProvider from "../../data-provider";
import "./style.scss";
import {Icon16Done, Icon16ErrorCircle} from "@vkontakte/icons";

import {PAGE_COMMUNITY_HOME} from "./routes";
import {useInterests} from "../../hooks/useInterests";
import {ContextState} from "../../ContextState";
import AppParams from "../../utils/AppParams";
import {Controller, useForm} from "react-hook-form";

const Create = ({id, defaultClubFormValues, alert}) => {

    const {dispatch, state: {user, clubsData = {clubList: []}}} = React.useContext(ContextState);

    const [clubFormValues, setClubFormValues] = React.useState({
        owner: user.id,
        isActive: true,
        ...defaultClubFormValues
    });
    const [clubFormSaving, setClubFormSaving] = React.useState(false);
    const [clubLinkLoading, setClubLinkLoading] = React.useState(false);
    const [removeButtonLoading, setRemoveButtonLoading] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState(null);
    const [defaultClubValues, setDefaultClubValues] = React.useState({});

    const {clubList} = clubsData;
    const router = useRouter();
    const platform = usePlatform();
    const {interests: interestList} = useInterests();
    const {control, handleSubmit, reset} = useForm({
        defaultValues: defaultClubValues,
        resetOptions: {
            keepDirtyValues: true
        }
    });

    React.useEffect(() => {
        reset(defaultClubValues);
    }, [defaultClubValues]);



    const onClubLinkChange = (ev) => {

        if (clubFormValues.id) {
            return;
        }

        let value = ev.currentTarget.value;
        if (value.substring(0, 7) === 'vk.com/') {
            value = 'https://' + value;
        }
        //https://vk.com/volontermgupp
        if (value.substring(0, 15) === 'https://vk.com/') {
            const grpId = value.substring(15);
            setClubLinkLoading(true);
            dataProvider().getList('community', {
                filter: {
                    link: value
                }
            })
                .then(({data}) => {
                    if (data.length > 0) {
                        return Promise.reject(new Error('Ссылка добавлена ранее'))
                    } else {
                        return Promise.resolve(true)
                    }
                })
                .then(() => {
                    dataProvider().fetch('fetch/groupsGetById', {
                        method: 'post',
                        data: {
                            group_id: grpId,
                            fields: 'description,status,city,members_count'
                        }
                    }).then(({data: clubData}) => {

                        const img = new Image;
                        const c = document.createElement("canvas");
                        const ctx = c.getContext("2d");

                        img.onload = function() {
                            c.width = this.naturalWidth;     // update canvas size to match image
                            c.height = this.naturalHeight;
                            ctx.drawImage(this, 0, 0);       // draw in image
                            c.toBlob((blob) => {
                                setDefaultClubValues({
                                    ...clubFormValues,
                                    ...(clubData.city ? {city: clubData.city.id} : {}),
                                    link: value,
                                    description: clubData.status + "\n\n" + clubData.description,
                                    title: clubData.name,
                                    externalId: clubData.id,
                                    membersCount: clubData.members_count,
                                    logo: new File([blob], "test.jpg")
                                });
                            });
                        }
                        img.src = 'data:' + clubData['photo_content_type'] + ';base64, ' + clubData['photo_binary'];


                    })
                })
                .catch((error) => {
                    setSnackbar(<Snackbar
                        onClose={() => {
                            setSnackbar(null);
                        }}
                        before={
                            <Avatar
                                size={24}
                                style={{background: "var(--vkui--color_background_negative)"}}
                            >
                                <Icon16ErrorCircle fill="#fff" width={14} height={14}/>
                            </Avatar>
                        }
                    >
                        Произошла ошибка. {error.message}
                    </Snackbar>)
                })
                .finally(() => {
                    setClubLinkLoading(false);
                })


        } else if (value.length) {
            setSnackbar(<Snackbar
                onClose={() => {
                    setSnackbar(null);
                }}
                before={
                    <Avatar
                        size={24}
                        style={{background: "var(--vkui--color_background_negative)"}}
                    >
                        <Icon16ErrorCircle fill="#fff" width={14} height={14}/>
                    </Avatar>
                }
            >
                Не смог распознать ссылку
            </Snackbar>)
        }
    }

    const onFormSubmit = (data) => {

        setClubFormSaving(true);

        (() => {
            if (data.id) {
                return dataProvider().update('community', {
                    id: data.id,
                    data: data
                })
            } else {
                return dataProvider().create('community', {
                    data: data
                })
            }

        })().then(({data}) => {
            setClubFormValues({
                ...data,
                tags: data.tags.map(item => item.id)
            })
            setSnackbar(
                <Snackbar
                    onClose={() => {
                        setSnackbar(null);
                        !data.id && router.pushPage(PAGE_COMMUNITY_HOME)
                    }}
                    before={
                        <Avatar
                            size={24}
                            style={{background: "var(--vkui--color_background_accent)"}}
                        >
                            <Icon16Done fill="#fff" width={14} height={14}/>
                        </Avatar>
                    }
                >
                    {data.id ? 'Изменения успешно внесены' : 'Клуб успешно отправлен на модерацию'}
                </Snackbar>
            );


        }).finally(() => {
            setClubFormSaving(false);
        }).catch(() => {

        })

    }

    const onClubRemoveClick = () => {
        alert(<Alert
            actions={[{
                title: "Подтвердить", mode: "destructive", autoClose: true, action: () => {
                    setRemoveButtonLoading(true);
                    dataProvider().delete('club', {
                        id: clubFormValues.id
                    }).then(() => {
                        dispatch({
                            type: 'setState',
                            payload: {
                                clubsData: {
                                    clubList: [...clubList.filter(item => parseInt(item.id) !== parseInt(clubFormValues.id))],
                                }
                            }
                        });
                        router.replacePage(PAGE_COMMUNITY_HOME)
                    }).finally(() => {
                        setRemoveButtonLoading(false);
                    })
                },
            }, {
                title: "Отмена", autoClose: true, mode: "cancel",
            },]}
            actionsLayout="vertical"
            onClose={() => alert(null)}
            header="Подтвердите действие"
            text={"Вы уверены, что хотите удалить клуб?"}
        />);
    }

    return <Panel id={id}>
        <PanelHeader separator={false} before={
            <PanelHeaderBack
                onClick={() => router.pushPage(PAGE_COMMUNITY_HOME)}
                label={platform === Platform.VKCOM ? "Назад" : undefined}
            />
        }>
            {clubFormValues.id ? 'Редактирование клуба' : 'Новый клуб'}
        </PanelHeader>

        <FormLayout className="clubs-create" onSubmit={handleSubmit(onFormSubmit)}>
            <Controller
                name="link"
                control={control}
                rules={{required: 'Введите ссылку'}}
                render={({field: {ref, onBlur, ...fieldProps}, fieldState: {error, invalid}}) => {
                    return <FormItem top="Ссылка на клуб"
                                     status={invalid ? "error" : ""}
                                     bottom={error?.message}
                    >
                        <Input type="url"
                               name="link"
                               autoComplete="off"
                               readOnly={!!defaultClubValues.externalId}
                               onBlur={(ev) => {
                                   onClubLinkChange(ev);
                                   onBlur(ev);
                               }}
                               getRef={ref}
                               {...fieldProps}/>
                    </FormItem>
                }}
            />

            {!!defaultClubValues.externalId &&
                <>
                    <Controller
                        name="title"
                        control={control}
                        rules={{
                            required: 'Введите название',
                            maxLength: {
                                value: 120,
                                message: 'Максимальная длина 120 символов'
                            }
                        }}
                        render={({field: {ref, ...fieldProps}, fieldState: {error, invalid}}) => {
                            return <FormItem top="Название клуба"
                                             status={invalid ? "error" : ""}
                                             bottom={error?.message}
                            >
                                <Input type="text"
                                       maxLength={120}
                                       autoComplete="off"
                                       getRef={ref}
                                       {...fieldProps}/>
                            </FormItem>
                        }}
                    />
                    <Controller
                        name="description"
                        control={control}
                        rules={{
                            required: 'Введите описание',
                            maxLength: {
                                value: 600,
                                message: 'Максимальная длина 600 символов'
                            }
                        }}
                        render={({field: {ref, ...fieldProps}, fieldState: {error, invalid}}) => {
                            return <FormItem top="Название клуба"
                                             status={invalid ? "error" : ""}
                                             bottom={error?.message}
                            >
                                <Textarea type="text" aria-multiline
                                          maxLength={600}
                                          autoComplete="off"
                                          getRef={ref}
                                          {...fieldProps}/>
                            </FormItem>
                        }}
                    />

                    <Controller
                        name="tags"
                        control={control}
                        rules={{required: 'Введите теги'}}
                        render={({field: {ref, onChange, value = [], ...fieldProps}, fieldState: {error, invalid}}) => {
                            return <FormItem
                                status={invalid ? "error" : ""}
                                bottom={error?.message}
                            >
                                <ChipsSelect
                                    {...fieldProps}
                                    value={interestList.filter(item => value.includes(item.id)).map(item => ({value: item.id, label: item.name}))}
                                    placeholder="Выберите теги"
                                    creatable={false}
                                    onChange={v => onChange(v.map(({value}) => value))}
                                    options={interestList.map(item => ({value: item.id, label: item.name}))}
                                    getRef={ref}
                                />
                            </FormItem>
                        }}
                    />
                    <Controller
                        name="membersCount"
                        control={control}
                        rules={{
                            required: 'Введите количество участников',
                        }}
                        render={({field: {ref, ...fieldProps}, fieldState: {error, invalid}}) => {
                            return <FormItem top="Количество участников"
                                             status={invalid ? "error" : ""}
                                             bottom={error?.message}
                            >
                                <Input type="number"
                                       autoComplete="off"
                                       getRef={ref}
                                       {...fieldProps}/>
                            </FormItem>
                        }}
                    />
                    <Controller
                        name="isActive"
                        control={control}
                        render={({field: {ref, value, ...fieldProps}, fieldState: {error, invalid}}) => {
                            return <FormItem
                                status={invalid ? "error" : ""}
                                bottom={error?.message}
                            >
                                <Checkbox
                                    getRef={ref}
                                    defaultChecked={!defaultClubValues[fieldProps.name]}
                                    {...fieldProps}
                                >Скрыть запись</Checkbox>
                            </FormItem>
                        }}
                    />

                    <Controller
                        name="logo"
                        control={control}
                        defaultValue={defaultClubValues.logo}
                        rules={{
                            required: 'выберите лого',
                        }}

                        render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                            return <>
                                <Div className="preview">
                                    {value && <img src={value.previewUri || URL.createObjectURL(value)} style={{maxWidth: "100%", height: 'auto'}}/>}
                                </Div>
                                <FormItem
                                    status={invalid ? "error" : ""}
                                    bottom={error?.message}
                                >
                                    <FileInput size="m" stretched align="center" name="image" accept="image/*"
                                               getRef={ref}
                                               {...restField}
                                               onChange={(ev) => restField.onChange(ev.target.files[0])}
                                    >
                                        Заменить лого
                                    </FileInput>
                                </FormItem>
                            </>
                        }}
                    />
                    <FormItem>
                        <ButtonGroup gap="s">
                            {defaultClubValues.id && <Button size="m" appearance="negative" loading={removeButtonLoading} onClick={onClubRemoveClick}>
                                Удалить
                            </Button>}
                            <Button size="m" type="submit" loading={clubFormSaving} disabled={!defaultClubValues.link} stretched>
                                {clubFormValues.id ? 'Сохранить данные' : 'Добавить клуб'}
                            </Button>
                        </ButtonGroup>
                    </FormItem>
                </>

            }
            {!defaultClubValues.externalId && clubLinkLoading && <Div style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                <Spinner size="large"/>
            </Div>}

        </FormLayout>
        {snackbar}
    </Panel>
};


export default Create;
