import React, {useState, useEffect} from 'react';
import dataProvider from "../data-provider";

export function useStudSpaces(user, ref) {

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const findStudSpaces = (prop, value) => {
        return data.reduce((previousValue, currentValue) => {
            if (currentValue[prop] === value) {
                previousValue.push(currentValue)
            }

            return previousValue;
        }, [])
    }

    useEffect(() => {
        if (ref.current) {
            (async () => {
                try {
                    const res = await dataProvider('/api').getList('stud-space', {
                        filter: {
                            university: user.university.id
                        }
                    });
                    setData(res.data);

                } catch (err) {
                    setError(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
        return () => {
            ref.current = false;
        };
    }, [user, ref]);

    return {loading, data, error, findStudSpaces};
}
