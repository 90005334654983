import React from "react";

const Progress = ({circeProps = {}, percent = 0, ...props}) => {

    const {stroke = "#8C64D8", strokeWidth = 4} = circeProps;

    const pathRef = React.useRef(null);
    const [pathLength, setPathLength] = React.useState(0);
    const [pathLengthCurrent, setPathLengthCurrent] = React.useState(pathLength);
    const [circlePath, setCirclePath] = React.useState("M24.9 2.2C12.3 2.2 2 12.4 2 25C2 37.6 12.3 47.8 24.9 47.8C37.5 47.8 47.7 37.6 47.7 25C47.7 12.4 37.5 2.2 24.9 2.2Z");

    React.useEffect(() => {
        const length = Math.round(pathRef.current.getTotalLength());
        setPathLength(length);
        setPathLengthCurrent(-1 * Math.round(length - percent / 100 * length));

    }, [percent]);


    return <svg width="50" height="50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
            <path
                d={circlePath} stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" strokeDasharray="1 8"/>
            <path
                d={circlePath} stroke={stroke} strokeWidth={strokeWidth} strokeDasharray={pathLength + " " + pathLength} strokeDashoffset={pathLengthCurrent}
                strokeMiterlimit={10}
                ref={pathRef}/>
        </g>
    </svg>
}


export default Progress;