import {Page} from "@happysanta/router";


export const PAGE_EVENT_HOME = '/event';
export const PANEL_EVENT_HOME = 'panel_event';

export const PAGE_EVENT_CREATE = '/event-create';
export const PANEL_EVENT_CREATE = 'panel_event_create';

export const PAGE_EVENT_SHOW = '/event-show/:id([0-9]+)';
export const PANEL_EVENT_SHOW = 'panel_event_show';

const VIEW_EPIC = 'view_epic';

export const routes = {
    [PAGE_EVENT_HOME]: new Page(PANEL_EVENT_HOME, VIEW_EPIC),
    [PAGE_EVENT_CREATE]: new Page(PANEL_EVENT_CREATE, VIEW_EPIC),
    [PAGE_EVENT_SHOW]: new Page(PANEL_EVENT_SHOW, VIEW_EPIC),
};