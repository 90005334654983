import React, {useState, useEffect, useLayoutEffect} from 'react';
import bridge from '@vkontakte/vk-bridge';
import {
    View,
    ScreenSpinner,
    AdaptivityProvider,
    Root,
    AppRoot,
    Tabbar,
    TabbarItem,
    ConfigProvider,
    SplitLayout,
    SplitCol,
    Epic,
    withAdaptivity,
    ModalRoot, ModalPage, VKCOM, Badge
} from '@vkontakte/vkui';
import {useRouter, withRouter} from '@happysanta/router';
import './theme/index.scss';
import "@vkontakte/vkui/dist/unstable.css";

import {useLocation} from '@happysanta/router';
import dataProvider from "./data-provider";

import AppParams from "./utils/AppParams";

import {

    PAGE_WELCOME,

    PANEL_WELCOME,
    VIEW_EPIC,
    VIEW_MAIN
} from "./router";

import  {
    PAGE_PROFILE,
    PANEL_PROFILE,
    PANEL_PROFILE_BUG_REPORT
} from './pages/profile/routes'

import {
    PAGE_BOOKING_HOME,
    PANEL_BOOKING_HOME,
    PANEL_BOOKING_CREATE,
    PANEL_BOOKING_START,
    PANEL_BOOKING_VERIFY,
} from "./pages/booking/routes"

import {
    PANEL_EVENT_HOME,
    PAGE_EVENT_HOME,
    PANEL_EVENT_CREATE,
     PANEL_EVENT_SHOW
} from "./pages/event/routes";

import {ContextState} from "./ContextState";
import {Icon28AdvertisingOutline, Icon28TicketOutline, Icon28UserCircleOutline, Icon28Users3Outline} from "@vkontakte/icons";


import {BookingHome, BookingStart, BookingCreate, BookingVerify} from "./pages/booking";

import {Event, EventCreate, EventShow} from "./pages/event";
import Welcome from "./pages/welcome";
import {CommunityHome, CommunityCreate} from "./pages/community";
import Profile from "./pages/profile";

import "./App.scss";
import {
    PAGE_COMMUNITY_HOME,
    PANEL_COMMUNITY_CREATE,
    PANEL_COMMUNITY_HOME
} from "./pages/community/routes";
import ProfileBugReport from "./pages/profile/bugReport";

const App = withAdaptivity(
    ({viewHeight}) => {
        const [scheme, setScheme] = useState('stud')
        const [loading, setLoading] = useState(true)
        const [popout, setPopout] = useState(<ScreenSpinner size='large'/>);
        const [modalPages, setModalPages] = useState([]);
        const [activeModal, setActiveModal] = useState();
        const {dispatch} = React.useContext(ContextState);


        const location = useLocation();
        const router = useRouter();

        useEffect(() => {
            let isMounted = true;
            setScheme(scheme);
            setLoading(true)
            bridge
                .send('VKWebAppGetUserInfo')
                .then((userInfo) => {
                    return dataProvider('/api')
                        .update('user', {
                            id: userInfo['id'],
                            data: {...userInfo, 'photo': userInfo['photo_max_orig']}
                        });

                })
                .then(({data}) => {
                    if (isMounted) {
                        dispatch({
                            type: 'setState',
                            payload: {
                                user: data
                            }
                        });
                        if (!data.welcomeShown || !data.university) {
                            router.replacePage(PAGE_WELCOME);
                        }
                    }
                })
                .finally(() => {
                    setPopout(null);
                    setLoading(false)
                });
            return () => {
                isMounted = false;
            }
        }, []);

        const modal = (
            <ModalRoot activeModal={activeModal}>
                {modalPages}
            </ModalRoot>
        );

        const showModal = (content, {id, ...props}) => {

            if (!content) {
                return setActiveModal(undefined);
            }

            !modalPages.find((m) => m.props.id !== id) && setModalPages([...modalPages, <ModalPage   {...props} id={id} key={id} onClosed={() => {
                const _modals = modalPages.filter((m) => m.props.id !== id)
                setModalPages(_modals);
            }} onClose={() => {
                setActiveModal(undefined);
            }}>
                {content}
            </ModalPage>]);
            setActiveModal(id);
        }

        return (
            <ConfigProvider scheme="inherit">
                <AdaptivityProvider>
                    <AppRoot>
                        <Root activeView={location.getViewId()} className={"platform__" + AppParams.vk_platform}>
                            <SplitLayout popout={popout} nav={location.getViewId()} modal={modal}>
                                <SplitCol>
                                    {!loading && location.getViewId() === VIEW_MAIN &&
                                        <View id={VIEW_MAIN}
                                              history={location.getViewHistory(VIEW_MAIN)}
                                              activePanel={location.getViewActivePanel(VIEW_MAIN)}>
                                            <Welcome id={PANEL_WELCOME}/>
                                        </View>
                                    }
                                    {!loading && location.getViewId() === VIEW_EPIC && (
                                        <Epic
                                            id={VIEW_EPIC}
                                            activeStory={location.getViewActivePanel(VIEW_EPIC)}
                                            tabbar={(
                                                <Tabbar>
                                                    <TabbarItem
                                                        onClick={() => router.pushPage(PAGE_BOOKING_HOME)}
                                                        selected={location.getViewActivePanel(VIEW_EPIC).substring(0, 13) === 'panel_booking'}
                                                        text="Бронь"
                                                    >
                                                        <Icon28TicketOutline/>
                                                    </TabbarItem>
                                                    <TabbarItem
                                                        onClick={() => router.pushPage(PAGE_EVENT_HOME)}
                                                        selected={location.getViewActivePanel(VIEW_EPIC).substring(0, 11) === 'panel_event'}
                                                        text="События"
                                                    >
                                                        <Icon28AdvertisingOutline/>
                                                    </TabbarItem>
                                                    <TabbarItem
                                                        onClick={() => router.pushPage(PAGE_COMMUNITY_HOME)}
                                                        selected={location.getViewActivePanel(VIEW_EPIC).substring(0, 15) === 'panel_community'}
                                                        text="Cообщества"
                                                    >
                                                        <Icon28Users3Outline/>
                                                    </TabbarItem>
                                                    <TabbarItem
                                                        onClick={() => router.pushPage(PAGE_PROFILE)}
                                                        selected={location.getViewActivePanel(VIEW_EPIC).substring(0, 13) === 'panel_profile'}
                                                        text="Профиль"
                                                    >
                                                        <Icon28UserCircleOutline/>
                                                    </TabbarItem>
                                                </Tabbar>
                                            )}
                                        >

                                            <View id={PANEL_BOOKING_HOME} activePanel={PANEL_BOOKING_HOME}>
                                                <BookingHome id={PANEL_BOOKING_HOME} showModal={showModal}/>
                                            </View>
                                            <View id={PANEL_BOOKING_START} activePanel={PANEL_BOOKING_START}>
                                                <BookingStart id={PANEL_BOOKING_START} showModal={showModal}/>
                                            </View>
                                            <View id={PANEL_BOOKING_CREATE} activePanel={PANEL_BOOKING_CREATE}>
                                                <BookingCreate id={PANEL_BOOKING_CREATE} showModal={showModal}/>
                                            </View>
                                            <View id={PANEL_BOOKING_VERIFY} activePanel={PANEL_BOOKING_VERIFY}>
                                                <BookingVerify id={PANEL_BOOKING_VERIFY}/>
                                            </View>

                                            <View id={PANEL_EVENT_HOME} activePanel={PANEL_EVENT_HOME}>
                                                <Event id={PANEL_EVENT_HOME}/>
                                            </View>
                                            <View id={PANEL_EVENT_CREATE} activePanel={PANEL_EVENT_CREATE}>
                                                <EventCreate id={PANEL_EVENT_CREATE} showModal={showModal}/>
                                            </View>
                                            <View id={PANEL_EVENT_SHOW} activePanel={PANEL_EVENT_SHOW}>
                                                <EventShow id={PANEL_EVENT_SHOW}/>
                                            </View>
                                            <View id={PANEL_COMMUNITY_HOME} activePanel={PANEL_COMMUNITY_HOME}>
                                                <CommunityHome id={PANEL_COMMUNITY_HOME}/>
                                            </View>
                                            <View id={PANEL_COMMUNITY_CREATE} activePanel={PANEL_COMMUNITY_CREATE}>
                                                <CommunityCreate id={PANEL_COMMUNITY_CREATE}/>
                                            </View>

                                            <View id={PANEL_PROFILE} activePanel={PANEL_PROFILE}>
                                                <Profile id={PANEL_PROFILE} alert={setPopout} showModal={showModal}/>
                                            </View>
                                            <View id={PANEL_PROFILE_BUG_REPORT} activePanel={PANEL_PROFILE_BUG_REPORT}>
                                                <ProfileBugReport id={PANEL_PROFILE_BUG_REPORT} alert={setPopout}/>
                                            </View>
                                        </Epic>
                                    )}

                                </SplitCol>
                            </SplitLayout>
                        </Root>
                    </AppRoot>
                </AdaptivityProvider>
            </ConfigProvider>
        );
    },
    {
        viewHeight: true,
    }
)

export default withRouter(App);
