import {Page} from "@happysanta/router";


export const PAGE_COMMUNITY_HOME = '/community';
export const PANEL_COMMUNITY_HOME = 'panel_community';

export const PAGE_COMMUNITY_CREATE = '/community-create';
export const PANEL_COMMUNITY_CREATE = 'panel_community_create';


const VIEW_EPIC = 'view_epic';

export const routes = {
    [PAGE_COMMUNITY_HOME]: new Page(PANEL_COMMUNITY_HOME, VIEW_EPIC),
    [PAGE_COMMUNITY_CREATE]: new Page(PANEL_COMMUNITY_CREATE, VIEW_EPIC),
};