import React, {useState} from 'react';
import {
    Panel,
    PanelHeader,
    Button, usePlatform,
    Div, PanelHeaderBack, VKCOM, FormItem, Input, Group, File, FormLayout, Snackbar, Avatar, Textarea,

} from '@vkontakte/vkui';
import "./style.scss";

import {PAGE_PROFILE} from "./routes";
import {useRouter} from "@happysanta/router";
import dataProvider from "../../data-provider";
import {Icon28UserAddOutline} from "@vkontakte/icons";
import {PAGE_BOOKING_HOME} from "../booking/routes";
import "./style.scss";

const ProfileBugReport = ({id, alert}) => {
    const router = useRouter();
    const platform = usePlatform();

    const [formValues, setFormValues] = React.useState({
        screenshots: []
    });

    const [formErrors, setFormErrors] = React.useState({});
    const [snackbar, setSnackbar] = React.useState(null);

    const onFormChange = (ev) => {
        const target = ev.currentTarget;
        const _values = formValues;
        if (target.type === 'file') {
            _values[target.name] = _values[target.name].concat(Array.from(target.files));
        } else {
            _values[target.name] = target.value;
        }

        setFormValues(_values);
        const errors = {...formErrors};
        if (_values[target.name].length) {
            delete errors[target.name];
        }
        setFormErrors(errors)

    }
    const onFormSubmit = (ev) => {
        ev.preventDefault()
        const errors = {};
        ['description', 'screenshots'].forEach((k) => {
            if (!formValues[k] || formValues[k].length === 0) {
                if (k === 'screenshots') {
                    errors[k] = 'Пожалуйста, загрузите скриншот';
                } else {
                    errors[k] = 'Пожалуйста, введите ';
                    switch (k) {
                        case 'description':
                            errors[k] += 'описание';
                            break;

                    }
                }
            } else {
                delete errors[k];
            }
        })

        if (Object.keys(errors).length) {
            setFormErrors(errors)
        } else {
            dataProvider()
                .create('bug-report', {data : formValues})
                .then((json) => {
                    setFormValues({
                        'screenshots': []
                    })
                    setSnackbar(
                        <Snackbar
                            onClose={() => setSnackbar(null)}
                            before={
                                <Avatar style={{fill: 'var(--accent)'}}>
                                    <Icon28UserAddOutline fill="inherit"/>
                                </Avatar>
                            }
                        >
                            Урра! Данные об ошибке были отправлены разработчикам Студ.Пространства на модерацию. Как только мы проверим, ты получишь
                            уведомление.
                        </Snackbar>
                    );
                });


        }
    }

    return <Panel id={id}>

        <PanelHeader separator={false}
                     before={
                         <PanelHeaderBack
                             onClick={() => router.pushPage(PAGE_PROFILE)}
                             label={platform === VKCOM ? "Назад" : undefined}
                         />
                     }>
            Сообщить об ошибке
        </PanelHeader>
        <FormLayout onSubmit={onFormSubmit}>
            <Div className="form-description">

            </Div>
            <FormItem
                status={formErrors.description ? "error" : ""}
                bottom={formErrors.description}
            >
                <Textarea
                    placeholder="Описание"
                    name="description"
                    defaultValue={formValues.description}
                    onChange={onFormChange}
                />
            </FormItem>

            <Group>
                {(formValues.screenshots && formValues.screenshots.length) ?
                    <Div className="previews">
                        {formValues.screenshots.map((v, idx) => <img key={idx} src={v.previewUri || URL.createObjectURL(v)} onClick={() => {
                            setFormValues({
                                ...formValues,
                                screenshots : formValues['screenshots'].splice(idx+1, 1)
                            })
                        }}/>)}
                    </Div> : null}
                <FormItem
                    status={formErrors.screenshots ? "error" : ""}
                    bottom={formErrors.screenshots}>
                    <File size="s" align="center" name="screenshots" multiple={true} accept="image/*" onChange={onFormChange}>
                        Загрузить скриншоты
                    </File>
                </FormItem>
                <FormItem>
                    <Button size="l" stretched align="center" type="submit">
                        Отправить
                    </Button>
                </FormItem>
            </Group>
        </FormLayout>
        {snackbar}
    </Panel>
};


export default ProfileBugReport;
