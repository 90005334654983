import {stringify} from 'qs';


var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function (t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};

Date.prototype.toIsoStringWithoutZoneShift = function () {
    const tzo = -this.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-',
        pad = (num) => {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
    return this.getFullYear() +
        '-' + pad(this.getMonth() + 1) +
        '-' + pad(this.getDate()) +
        'T' + pad(this.getHours()) +
        ':' + pad(this.getMinutes()) +
        ':' + pad(this.getSeconds()) +
        dif + pad(tzo / 60) +
        ':' + pad(tzo % 60);
}

const convertObjectToFormData = function (data) {

    const headers = new Headers(), formData = new FormData(),
        urlData = new URLSearchParams();
    //headers.set('Content-Type', 'application/x-www-form-urlencoded');

    const convert = (data = {}, form = null, namespace = '', headers) => {

        let files = {}, model = {};

        if (data instanceof File) {
            files[namespace] = data;
        } else if (data.hasOwnProperty('rawFile') && data['rawFile'] instanceof File) {
            files[namespace] = data['rawFile'];
        } else {
            for (let propertyName in data) {
                if (data.hasOwnProperty(propertyName) && data[propertyName] instanceof File) {
                    if (namespace) {
                        files[namespace + '[' + propertyName + ']'] = data[propertyName];
                    } else {
                        files[propertyName] = data[propertyName];
                    }
                } else if (data.hasOwnProperty(propertyName) && typeof data[propertyName] === 'object' && data[propertyName]
                    && data[propertyName].hasOwnProperty('rawFile') && data[propertyName]['rawFile'] instanceof File) {
                    if (namespace) {
                        files[namespace + '[' + propertyName + ']'] = data[propertyName]['rawFile'];
                    } else {
                        files[propertyName] = data[propertyName]['rawFile'];
                    }
                } else {
                    model[propertyName] = data[propertyName]
                }
            }
        }

        for (let propertyName in model) {
            if (!model.hasOwnProperty(propertyName)) continue;
            let formKey = namespace ? `${namespace}[${propertyName}]` : propertyName;
            if (model[propertyName] instanceof Date)
                formData.append(formKey, model[propertyName].toIsoStringWithoutZoneShift());
            else if (model[propertyName] instanceof File) {
                formData.append(formKey, model[propertyName]);
            } else if (model[propertyName] instanceof Array) {
                if (model[propertyName].length > 0) {
                    model[propertyName].forEach((element, index) => {
                        const tempFormKey = `${formKey}[${index}]`;
                        if (typeof element === 'object') {
                            convert(element, formData, tempFormKey, headers);
                        } else {
                            formData.append(tempFormKey, element.toString());
                        }
                    });
                }else {
                    formData.append(formKey, []);
                }

            } else if (typeof model[propertyName] === 'object' && model[propertyName] && !(model[propertyName] instanceof File)) {
                convert(model[propertyName], formData, formKey, headers);
            } else if (typeof model[propertyName] === "boolean") {
                formData.append(formKey, model[propertyName] ? '1' : '0');
            } else if (model[propertyName] === null || model[propertyName] === undefined) {
                formData.append(formKey, '');
            } else {
                formData.append(formKey, model[propertyName].toString());
            }
        }

        for (let propertyName in files) {
            if (files.hasOwnProperty(propertyName)) {
                formData.append(propertyName, files[propertyName]);
            }
            //headers.set('Content-Type', 'multipart/form-data');
        }
    };

    convert(data, formData, '', headers);
    /*
        if (headers.get('Content-Type') === 'application/x-www-form-urlencoded') {
            for (let pair of formData.entries()) {
                urlData.append(...pair);
            }
            headers.delete('Content-Type');
            return [urlData, headers]
        }
    */
    //headers.delete('Content-Type');
    return [formData, headers];
}


/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @see https://github.com/typicode/json-server
 *
 * @example
 *
 * getList          => GET http://my.api.url/posts?_sort=title&_order=ASC&_start=0&_end=24
 * getOne           => GET http://my.api.url/posts/123
 * getManyReference => GET http://my.api.url/posts?author_id=345
 * getMany          => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * create           => POST http://my.api.url/posts/123
 * update           => PUT http://my.api.url/posts/123
 * updateMany       => PUT http://my.api.url/posts/123, PUT http://my.api.url/posts/456, PUT http://my.api.url/posts/789
 * delete           => DELETE http://my.api.url/posts/123
 *
 * @example
 *
 * import * as React from "react";
 * import { Admin, Resource } from 'react-admin';
 * import jsonServerProvider from 'ra-data-json-server';
 *
 * import { PostList } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={jsonServerProvider('http://jsonplaceholder.typicode.com')}>
 *         <Resource name="posts" list={PostList} />
 *     </Admin>
 * );
 *
 * export default App;
 */
const dataProvider = (function (apiUrl) {

    const httpClient = (url, params = {}) => {
        params['headers'] = params['headers'] || new Headers();
        params['headers'].append('Vk-Auth-Uri', location.search);
        if (!params['headers'].has('Accept')) {
            params['headers'].append('Accept', 'application/json');
        }

        return fetch(url, params)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    const error = new Error(response.statusText);
                    error.status = response.status;
                    if (response.headers.get('Content-Type') === 'application/json') {
                        return response.json().then(j => {
                            error.cause = j;
                            throw error;
                        });
                    } else {
                        return response.text().then(t => {
                            error.cause = t;
                            throw error;
                        });
                    }

                }
                return response.json()
                    .then(json => {
                        const headers = response.headers;
                        return Promise.resolve({headers, json})
                    })
            })
            .catch((error) => {
                return Promise.reject(error);
            })
    }

    return ({

        getList: function (resource, params = {}) {
            const {pagination = {}, filter = {}, sort = {}, parameters = {}, ...rest} = params;
            let query = rest || {};
            if (sort) {
                const field = sort.field, order = sort.order;
                Object.assign(query, {
                    _sort: field,
                    _order: order,
                })
            }
            if (pagination) {
                const page = pagination.page || 1, perPage = pagination.perPage || 50;
                Object.assign(query, {
                    _start: (page - 1) * perPage,
                    _end: page * perPage
                })
            }
            if (filter) {
                Object.assign(query, {
                    _filter: filter
                })
            }

            const url = apiUrl + "/" + resource + "?" + stringify(query);
            return httpClient(url, parameters)
                .then(_a => {
                    const {headers, json} = _a;
                    if (!headers.has('x-total-count')) {
                        throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
                    }
                    return {
                        data: json,
                        total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
                    };
                })
        },

        getOne: function (resource, params) {
            const {id, parameters} = params;
            return httpClient(apiUrl + "/" + resource + "/" + id, parameters).then(function (_a) {
                const json = _a.json;
                return ({
                    data: json,
                });
            });
        },
        getMany: function (resource, params) {
            var query = {
                _where: {
                    id: params.ids
                }

            };
            const url = apiUrl + "/" + resource + "?" + stringify(query);
            return httpClient(url).then(function (_a) {
                var json = _a.json;
                return ({data: json});
            });
        },
        getManyReference: function (resource, params) {
            const {pagination, filter, sort, ...rest} = params;
            let query = rest || {};
            if (sort) {
                const field = sort.field, order = sort.order;
                Object.assign(query, {
                    _sort: field,
                    _order: order,
                })
            }
            if (pagination) {
                const page = pagination.page, perPage = pagination.perPage;
                Object.assign(query, {
                    _start: (page - 1) * perPage,
                    _end: page * perPage
                })
            }

            if (filter) {
                Object.assign(query, {
                    _filter: filter
                })
            }

            const url = apiUrl + "/" + resource + "?" + stringify(query);
            return httpClient(url).then(function (_a) {
                const {json, headers} = _a;
                if (!headers.has('x-total-count')) {
                    throw new Error('The X-Total-Count header is missing in the HTTP Response. The jsonServer Data Provider expects responses for lists of resources to contain this header with the total number of results to build the pagination. If you are using CORS, did you declare X-Total-Count in the Access-Control-Expose-Headers header?');
                }
                return {
                    data: json,
                    total: parseInt(headers.get('x-total-count').split('/').pop(), 10),
                };
            });
        },
        update: function (resource, params) {
            const [formData, headers] = convertObjectToFormData(params.data);
            return httpClient(apiUrl + "/" + resource + "/" + params.id, {
                method: 'PUT',
                headers: headers,
                body: formData,
            }).then(function (_a) {
                var json = _a.json;
                return ({data: json});
            });
        },
        // json-server doesn't handle filters on UPDATE route, so we fallback to calling UPDATE n times instead
        updateMany: function (resource, params) {
            return Promise.all(params.ids.map(function (id) {
                const [formData, headers] = convertObjectToFormData(params.data[id]);
                return httpClient(apiUrl + "/" + resource + "/" + id, {
                    method: 'PUT',
                    headers: headers,
                    body: formData,
                });
            })).then(function (responses) {
                return ({
                    data: responses.map(function (_a) {
                        var json = _a.json;
                        return json.id;
                    })
                });
            });
        },
        create: function (resource, params) {
            const [formData, headers] = convertObjectToFormData(params.data);
            return httpClient(apiUrl + "/" + resource, {
                method: 'POST',
                headers: headers,
                body: formData,
            }).then(function (_a) {
                var json = _a.json;
                return ({
                    data: __assign(__assign({}, params.data), {id: json.id}),
                });
            });
        },
        delete: function (resource, params) {
            return httpClient(apiUrl + "/" + resource + "/" + params.id, {
                method: 'DELETE',
            }).then(function (_a) {
                var json = _a.json;
                return ({data: json});
            });
        },
        // json-server doesn't handle filters on DELETE route, so we fallback to calling DELETE n times instead
        deleteMany: function (resource, params) {
            return Promise.all(params.ids.map(function (id) {
                return httpClient(apiUrl + "/" + resource + "/" + id, {
                    method: 'DELETE',
                });
            })).then(function (responses) {
                return ({
                    data: responses.map(function (_a) {
                        var json = _a.json;
                        return json.id;
                    })
                });
            });
        },
        fetch: function (uri, params) {
            const {method, data, parameters = {}} = params
            parameters['method'] = method;
            if (data) {
                const [formData, headers] = convertObjectToFormData(data);
                parameters['headers'] = headers;
                parameters['body'] = formData;
            }

            return httpClient(apiUrl + '/' + uri, parameters)
                .then(function (_a) {
                    return ({data: _a.json});
                });

        }
    });
});

export {
    convertObjectToFormData
}
const dataProviderProxy = () => dataProvider('/api')
export default dataProviderProxy;