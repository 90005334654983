import {Page, Router} from '@happysanta/router';

export const VIEW_MAIN = 'view_main'
export const VIEW_EPIC = 'view_epic'

export const PAGE_WELCOME = '/welcome';
export const PANEL_WELCOME = 'panel_welcome';




import {routes as bookingRoutes} from "./pages/booking/routes";
import {routes as eventRoutes} from "./pages/event/routes";
import {routes as communityRoutes} from "./pages/community/routes";
import {routes as profileRoutes} from "./pages/profile/routes";

const routes = {
    [PAGE_WELCOME]: new Page(PANEL_WELCOME, VIEW_MAIN),

    ...bookingRoutes,
    ...eventRoutes,
    ...communityRoutes,
    ...profileRoutes
};

export const router = new Router(routes);

router.start();