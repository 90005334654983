import React, {useState} from 'react';

import {
    Panel, PanelHeader, IconButton, Div, Button,
    ButtonGroup, Switch,
    FormLayout,
    FormItem,
    DateRangeInput,
    Input,
    Checkbox, Banner,
    Group, Text, CustomSelect, ScreenSpinner, Avatar, CardGrid, Card
} from '@vkontakte/vkui';
import {useRouter} from '@happysanta/router';

import {ContextState} from "../../ContextState";
import "./style.scss";
import {Icon28SlidersOutline, Icon28NotebookCheckOutline, Icon16SearchOutline} from "@vkontakte/icons";
import moment from 'moment';
import {PAGE_EVENT_CREATE, PAGE_EVENT_SHOW} from "./routes";
import dataProvider from "../../data-provider";
import {useEventTypes} from "../../hooks/useEventTypes";
import plural from "plural-ru";

const Event = ({id}) => {

    const {
        dispatch, state: {
            user,
            eventsData = {eventList: [], page: 1},
            eventFilterParams = {}
        }
    } = React.useContext(ContextState);

    if (!Object.keys(eventFilterParams).includes('datesRange')) {
        eventFilterParams.datesRange = [new Date(), moment(new Date()).add(30, 'd').toDate()]
    }

    const [filtersShown, setFiltersShown] = React.useState(false);
    const [filterParameters, setFilterParameters] = useState(eventFilterParams);
    const [eventFetching, setEventFetching] = React.useState(false);
    const [eventButtonLoading, setEventButtonLoading] = React.useState(false);
    const [loadingEventRegisterButton, setLoadingEventRegisterButton] = React.useState(0);
    const [loadingEventUnRegisterButton, setLoadingEventUnRegisterButton] = React.useState(0);
    const [eventButtonDisabled, setEventButtonDisabled] = React.useState(false);
    const [abortController, setAbortController] = React.useState();
    const {eventTypes: eventTypeList} = useEventTypes();

    const {eventList = [], page = 1} = eventsData
    const router = useRouter();

    let isMounted = true;

    React.useEffect(() => {

        if (eventList.length) {
            return;
        }

        setEventFetching(true);
        fetchEvents(page)
            .finally(() => {
                setEventFetching(false);
            });

        return () => {
            isMounted = false;
            abortController && abortController.abort();
        };
    }, []);


    const fetchEvents = (page = 1) => {

        const _controller = new AbortController();
        setAbortController(_controller);

        const {datesRange, ..._filterParams} = filterParameters;

        return dataProvider('/api')
            .getList('event', {
                pagination: {perPage: 3, page},
                _where: {
                    ..._filterParams,
                    ...datesRange ? {
                        dateEnd: [{operator: 'lte', value: (moment(datesRange[1]).startOf('day').format())}, {operator: 'gte', value: (moment(datesRange[0]).startOf('day').format())}]
                    } : {}
                },
                sort: {
                    field: 'dateStart',
                    order: 'asc'
                },
                parameters: {signal: _controller.signal}
            })

            .then((json) => {

                if (json.data < 3) {
                    setEventButtonDisabled(true);
                }

                isMounted && dispatch({
                    type: 'setState',
                    payload: {
                        eventsData: {
                            eventList: [...(page === 1 ? [] : eventList), ...json.data],
                            page
                        }
                    }
                });

            })

    }

    const fetchMoreEvents = () => {
        setEventButtonLoading(true);
        fetchEvents(page + 1).finally(() => {
            setEventButtonLoading(false);
        });
    }


    const onFilterApply = (ev) => {
        ev.preventDefault();
        setFiltersShown(false);
        setEventFetching(true)
        setEventButtonDisabled(false);

        eventList.splice(0);
        dispatch({
            type: 'setState',
            payload: {
                eventFilterParams: filterParameters
            }
        })

        fetchEvents(1).finally(() => {
            setEventFetching(false);
        });
    }

    const onFilterParametersChange = (ev) => {

        const target = ev.currentTarget;
        const value = {};

        if (target.name === 'tags') {
            if (target.checked) {
                value[target.name] = user.interests.map(item => item.id);
            } else {
                value[target.name] = [];
            }


        } else if (target.type === 'checkbox') {
            if (target.checked) {
                value[target.name] = (filterParameters[target.name] || []).concat([target.value]);
            } else {
                value[target.name] = (filterParameters[target.name] || []).filter(item => item !== target.value);
            }
        } else {
            value[target.name] = target.value;
        }

        setFilterParameters({...filterParameters, ...value});
    }

    const formatDateRange = (event) => {
        const diff = moment(event.dateEnd).diff(event.dateStart, 'hours');
        if (diff <= 24) {
            return <>
                <span>{moment(event.dateStart).format('DD MMMM')}</span>{moment(event.dateStart).format(' (dd)')}<br/>
                <>{moment(event.dateStart).format(' HH:mm') + '-' + moment(event.dateEnd).format('HH:mm') + ' (' + diff + ' ' + plural(diff, 'час', 'часа', 'часов') + ')'}</>
            </>;
        } else {
            return moment(event.dateStart).format('DD MMMM (dd) HH:mm') + '-' + moment(event.dateEnd).format('DD MMMM (dd) HH:mm') + ' (' + diff + ' ' + plural(diff, 'час', 'часа', 'часов') + ')';
        }

    }

    const onEventRegisterClick = (ev) => {
        const {id: eventId} = ev.currentTarget.dataset;

        setLoadingEventRegisterButton(parseInt(eventId));

        dataProvider()
            .update('user', {
                id: user.id,
                data: {
                    registeredEvents: [...user.registeredEvents, {event: eventId}]
                }
            })
            .then(({data}) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        user: data
                    }
                });
                return  dataProvider('/api')
                    .getOne('event', {
                        id: eventId,
                    })
            })
            .then(({data}) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        eventsData: {
                            ...eventsData,
                            eventList: eventsData.eventList.map(r => r.id === data.id ? data : r)
                        }
                    }
                });
            })
            .finally(() => {
                setLoadingEventRegisterButton(0);
            })
    }

    const onEventUnRegisterClick = (ev) => {
        const {id: eventId} = ev.currentTarget.dataset;

        setLoadingEventUnRegisterButton(parseInt(eventId));

        dataProvider()
            .update('user', {
                id: user.id,
                data: {
                    registeredEvents: [...user.registeredEvents.filter(v => v.event.id !== parseInt(eventId))]
                }
            })
            .then(({data}) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        user: data
                    }
                })
                return  dataProvider('/api')
                    .getOne('event', {
                        id: eventId,
                    })
            })
            .then(({data}) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        eventsData: {
                            ...eventsData,
                            eventList: eventsData.eventList.map(r => r.id === data.id ? data : r)
                        }
                    }
                });
            })
            .finally(() => {
                setLoadingEventUnRegisterButton(undefined);
            })
    }

    return <Panel id={id}>
        <PanelHeader separator={false}>
            События
        </PanelHeader>
        <Div>
            <ButtonGroup stretched style={{justifyContent: "flex-end"}}>
                {!filtersShown && <Button stretched size="l" onClick={() => router.pushPage(PAGE_EVENT_CREATE)}>Добавить свое событие</Button>}
                <IconButton onClick={() => {
                    setFiltersShown(!filtersShown);
                }}>
                    <Icon28SlidersOutline fill="#8C64D8"/>
                </IconButton>
            </ButtonGroup>
        </Div>
        <FormLayout className={"events__filter-panel " + (filtersShown ? 'show' : '')} onSubmit={onFilterApply}>
            <FormItem top="Выберите даты">
                <div style={{display: "flex"}}>
                    <DateRangeInput style={{flex: '1 1 auto'}}
                                    name="datesRange"
                                    disablePast={true}
                                    placeholder="В любую дату"
                                    value={filterParameters.datesRange}
                                    onChange={(value) => {
                                        setFilterParameters({...filterParameters, datesRange: value})
                                    }}
                    />
                </div>
            </FormItem>
            <FormItem>
                <Input before={<Icon16SearchOutline aria-hidden/>}
                       autoComplete="off"
                       placeholder="По ключевому слову" name="query"
                       defaultValue={filterParameters._query}
                       onChange={(ev) => {
                           setFilterParameters({...filterParameters, _query: ev.currentTarget.value});
                       }}/>
            </FormItem>
            <FormItem>
                <Checkbox name="tags"
                          defaultChecked={filterParameters.tags && filterParameters.tags.length > 0}
                          onChange={onFilterParametersChange}>Учитывать мои интересы</Checkbox>
            </FormItem>
            <FormItem>
                <div className="checkbox-group">
                    {eventTypeList.map((item, idx) =>
                        <Checkbox key={idx} onChange={onFilterParametersChange} value={item.id}
                                  defaultChecked={filterParameters.types && filterParameters.types.includes(item.id + '')}
                                  name="types">{item.title}</Checkbox>)}
                </div>
            </FormItem>
            <FormItem>
                <Button size="m" mode="outline" type="submit" stretched>Применить фильтр</Button>
            </FormItem>
        </FormLayout>
        <Group>
            {eventFetching && <ScreenSpinner size='large'/>}
            {!eventFetching && eventList.length > 0 && <><CardGrid size="l">{eventList.map((item) => {

                    const userRegistered = user.registeredEvents.filter(re => re.event.id === item.id).length > 0;

                    return (<Card className="event-item" onClick={(ev) => {
                        const closestBtn = ev.target.closest('button');
                        if (!ev.target.matches('button') && !closestBtn) {
                            router.pushPage(PAGE_EVENT_SHOW, {id: item.id})
                        }
                    }}
                                  key={"event_" + item.id}>
                        <header>
                            {!!item.image && <Avatar
                                size={96}
                                mode="image"
                                src={item.image.previewUri}
                            />}
                            <h4>{item.title}</h4>
                            {!!item.tags.length && <ul className="tag-badges">{item.tags.map((tag) => <li key={"tag_" + tag.id}>{tag.name}</li>)}</ul>}
                            <div className="event-item__capacity">Мест
                                осталось: <strong>{item.maxParticipantsCount > 0 ? (Math.max(0, item.maxParticipantsCount - item.participantsCount)) : 'не' +
                                    ' ограничено'}</strong></div>
                        </header>
                        <div className="event-item__content">
                            {item.content}
                        </div>
                        <footer>
                            <div>
                                <Icon28NotebookCheckOutline fill="var(--accent)"/>
                                <div>{formatDateRange(item)}</div>
                            </div>
                            {moment(item.dateEnd).isAfter(new Date()) &&
                                <>
                                    {!userRegistered &&
                                        (item.maxParticipantsCount === 0 || (item.maxParticipantsCount - item.participantsCount) > 0) &&
                                        <Button size="s"
                                                onClick={onEventRegisterClick} data-id={item.id}
                                                loading={loadingEventRegisterButton === item.id}
                                        >
                                            Зарегистрироваться
                                        </Button>}
                                    {userRegistered &&
                                        <Button size="s"
                                                data-id={item.id}
                                                onClick={onEventUnRegisterClick}
                                                loading={loadingEventUnRegisterButton === item.id}>
                                            Отказаться от регистрации
                                        </Button>}
                                </>
                            }
                        </footer>
                    </Card>)
                }
            )}
            </CardGrid>
                {!eventButtonDisabled && <Div>
                    <Button size="l" disabled={eventButtonDisabled} loading={eventButtonLoading} onClick={fetchMoreEvents} stretched>Показать ещё</Button>
                </Div>}


            </>
            }

            {!eventFetching && eventList.length === 0 &&
                <Banner header="Не найдено" mode="tint"
                        text="По заданным параметрам событий не найдено."
                        actions={
                            <Button mode="outline" appearance="neutral" size="s" onClick={() => setFiltersShown(true)}>
                                Изменить параметры
                            </Button>
                        } style={{zIndex: 0}}>

                </Banner>}

        </Group>
    </Panel>
};


const CitySelect = (props) => {

    const [query, setQuery] = React.useState("");
    const [fetching, setFetching] = React.useState(false);
    const {dispatch, state: {eventCitySelectFetchedData: fetchedData}} = React.useContext(ContextState);

    const setFetchedData = (data) => {
        dispatch({
            type: 'setState',
            payload: {
                eventCitySelectFetchedData: data
            }
        })
    }

    const memorized = React.useMemo(() => {
        return {fetchTimer: 0, abortController: 0};
    }, []);

    React.useEffect(() => {
        return () => {
            memorized.fetchTimer && clearTimeout(memorized.fetchTimer)
            memorized.abortController && memorized.abortController.abort();
        };
    }, []);


    const fetchRemoteData = () => {

        memorized.abortController && memorized.abortController.abort();
        memorized.abortController = new AbortController()

        setFetching(true);
        dataProvider('/api')
            .getList('city', {
                pagination: {perPage: 20}, filter: {
                    _query: query
                },
                parameters: {signal: memorized.abortController.signal}
            })
            .then((json) => {
                setFetchedData(json.data.map((v) => {
                    return {value: v.id, label: v.name};
                }));
            })
            .catch((error) => {

            })
            .finally(() => {
                setFetching(false);
            });

    };

    const searchData = (e) => {
        const _remoteQuery = e.target.value;
        setQuery(_remoteQuery);
        if (_remoteQuery.length < 3) {
            setFetchedData([]);
            setFetching(false);
        } else {
            memorized.fetchTimer && clearTimeout(memorized.fetchTimer)
            memorized.fetchTimer = setTimeout(fetchRemoteData, 200);
        }
    };

    const renderDropdown = ({defaultDropdownContent}) => {
        if (query.length < 3) {
            return (<Text
                style={{padding: 12, color: "var(--vkui--color_text_secondary)"}}
            >
                Нужно ввести хотя бы три символа
            </Text>);
        }
        return defaultDropdownContent;
    };


    return <CustomSelect
        options={fetchedData}
        searchable={true}
        placeholder="Выберите город"
        onInputChange={searchData}
        fetching={fetching}
        renderDropdown={!fetching && renderDropdown}
        {...props}
    />;
}

export default Event;
