import React, {useState} from 'react';

import {
    Panel, PanelHeader, Div, Button,
    Group, ScreenSpinner, Avatar, GridAvatar, Card, PanelHeaderBack, VKCOM, usePlatform
} from '@vkontakte/vkui';
import {useParams, useRouter} from '@happysanta/router';

import {ContextState} from "../../ContextState";
import "./style.scss";
import {Icon28NotebookCheckOutline} from "@vkontakte/icons";
import moment from 'moment';
import {PAGE_EVENT_CREATE, PAGE_EVENT_HOME} from "./routes";
import dataProvider from "../../data-provider";

import plural from "plural-ru";

const EventShow = ({id}) => {

    const {
        dispatch, state: {
            user,
            eventsData = {eventList: []},
        }
    } = React.useContext(ContextState);

    const {id: eventId} = useParams();

    const [eventFetching, setEventFetching] = React.useState(false);
    const [participantAvatars, setParticipantAvatars] = React.useState([]);
    const [registerButtonLoading, setRegisterButtonLoading] = React.useState(false);
    const [unRegisterButtonLoading, setUnRegisterButtonLoading] = React.useState(false);
    const [abortController, setAbortController] = React.useState();
    const {eventList = []} = eventsData;
    const [eventRecord, setEventRecord] = React.useState(eventList.find(item => item.id = eventId));

    const router = useRouter();
    const platform = usePlatform();


    let isMounted = true;

    React.useEffect(() => {

        if (!eventRecord) {
            setEventFetching(true);
        }


        const _controller = new AbortController();
        setAbortController(_controller);

        dataProvider('/api')
            .getOne('event', {
                id: eventId,
                parameters: {signal: _controller.signal}
            })

            .then(({data}) => {
                setEventRecord(data)
                data.participantsCount > 0 && loadEventParticipantAvatars(data);
            }).finally(() => {
            setEventFetching(false);
        });

        return () => {
            isMounted = false;
            abortController && abortController.abort();
        };
    }, [eventId, user.registeredEvents]);


    const loadEventParticipantAvatars = ({id: event}) => {

        const _controller = new AbortController();
        setAbortController(_controller);

        dataProvider('/api')
            .fetch('fetch/eventParticipantAvatars', {
                method: 'post',
                data: {
                    event
                },
                parameters: {signal: _controller.signal}
            })

            .then(({data}) => {
                setParticipantAvatars(data);
            }).finally(() => {

        });
    }


    const formatDateRange = (event) => {
        const diff = moment(event.dateEnd).diff(event.dateStart, 'hours');
        if (diff <= 24) {
            return <>
                <span>{moment(event.dateStart).format('DD MMMM')}</span>{moment(event.dateStart).format(' (dd)')}<br/>
                <>{moment(event.dateStart).format(' HH:mm') + '-' + moment(event.dateEnd).format('HH:mm') + ' (' + diff + ' ' + plural(diff, 'час', 'часа', 'часов') + ')'}</>
            </>;
        } else {
            return moment(event.dateStart).format('DD MMMM (dd) HH:mm') + '-' + moment(event.dateEnd).format('DD MMMM (dd) HH:mm') + ' (' + diff + ' ' + plural(diff, 'час', 'часа', 'часов') + ')';
        }

    }

    const onEventRegisterClick = () => {
        const {id: eventId} = eventRecord;

        setRegisterButtonLoading(true);

        dataProvider()
            .update('user', {
                id: user.id,
                data: {
                    registeredEvents: [...user.registeredEvents, {event: eventId}]
                }
            })
            .then(({data}) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        user: data
                    }
                })
            })
            .finally(() => {
                setRegisterButtonLoading(false);
            })
    }
    const onEventUnRegisterClick = () => {
        const {id: eventId} = eventRecord;

        setUnRegisterButtonLoading(true);

        dataProvider()
            .update('user', {
                id: user.id,
                data: {
                    registeredEvents: [...user.registeredEvents.filter(v => v.event.id !== eventId)]
                }
            })
            .then(({data}) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        user: data
                    }
                })
            })
            .finally(() => {
                setUnRegisterButtonLoading(false);
            })
    }

    const userRegistered = user.registeredEvents.filter(re => re.event.id === eventRecord.id).length > 0;

    return <Panel id={id}>
        <PanelHeader separator={false} before={
            <PanelHeaderBack
                onClick={() => router.pushPage(PAGE_EVENT_HOME)}
                label={platform === VKCOM ? "Назад" : undefined}
            />
        }>
            События
        </PanelHeader>

        <Div>
            {eventFetching && <ScreenSpinner size='large'/>}
            {!eventFetching && !!eventRecord && <Card className="event-item" key={"event_" + eventRecord.id}>
                <header>
                    <Avatar
                        size={96}
                        mode="image"
                        src={eventRecord.image.previewUri}
                    />
                    <h4>{eventRecord.title}</h4>
                    {!!eventRecord.tags.length && <ul className="tag-badges">{eventRecord.tags.map((tag) => <li key={"tag_" + tag.id}>{tag.name}</li>)}</ul>}
                    <div className="event-item__capacity">Мест
                        осталось: <strong>{eventRecord.maxParticipantsCount > 0 ? (Math.max(0, eventRecord.maxParticipantsCount - eventRecord.participantsCount)) : 'не' +
                            ' ограничено'}</strong></div>
                </header>
                <div className="event-item__content">
                    {eventRecord.content}
                </div>
                <footer>
                    <div>
                        <Icon28NotebookCheckOutline fill="var(--accent)"/>
                        <div>{formatDateRange(eventRecord)}</div>
                    </div>
                    {moment(eventRecord.dateEnd).isAfter(new Date()) &&
                        <>
                            {!userRegistered &&
                                    (eventRecord.maxParticipantsCount === 0 || (eventRecord.maxParticipantsCount - eventRecord.participantsCount) > 0) &&
                                <Button size="s" onClick={onEventRegisterClick} loading={registerButtonLoading}>
                                    Зарегистрироваться
                                </Button>
                            }
                            {userRegistered && <Button size="s" onClick={onEventUnRegisterClick} loading={unRegisterButtonLoading}>
                                Отказаться от регистрации
                            </Button>}
                        </>
                    }

                </footer>
            </Card>
            }

        </Div>
        {participantAvatars.length > 0 && <Div>
            <div className="participant-avatars">
                <span>Уже идут:</span>
                <GridAvatar src={participantAvatars} size={32}></GridAvatar>
                <span>{eventRecord.participantsCount > 4 && '+' + eventRecord.participantsCount}</span>
            </div>
        </Div>}
    </Panel>
};


export default EventShow;
