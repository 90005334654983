import dataProvider from "../../data-provider";
import {CustomSelect} from "@vkontakte/vkui";
import React from 'react';

const UniversitySelect = ({initialOptions = [], ...props}) => {

    const [fetching, setFetching] = React.useState(false);
    const [fetchedInterests, setFetchedInterests] = React.useState(initialOptions);


    React.useEffect(() => {
        return () => fetchTimer && clearTimeout();
    }, []);


    let fetchTimer;
    const fetchRemoteStudSpaces = () => {

        if (fetchedInterests.length > 1) {
            return;
        }
        setFetching(true);

        dataProvider('/api')
            .getList('university', {
                pagination: {perPage: 500}
            })
            .finally(() => {
                setFetching(false);
            })
            .then((json) => {
                setFetchedInterests(json.data.map((v) => {
                    return {value: v.id, label: v.name};
                }));
            });

    };


    return <CustomSelect
        {...props}
        options={fetchedInterests}
        searchable={true}
        placeholder="Студ.Пространство"
        onOpen={fetchRemoteStudSpaces}
        fetching={fetching}

    />;
}

export {UniversitySelect}
export default UniversitySelect;