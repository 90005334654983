import React from 'react';
import PropTypes from 'prop-types';

import {Panel, Div, Button, Checkbox, FormItem, Select,} from '@vkontakte/vkui';
import {useRouter} from '@happysanta/router';

import './style.scss';

import dataProvider from "./../../data-provider";
import logo from "./img/logo.png";
import byImg from "./img/by.png";
import ddelo from "./img/ddelo.png";
import view3Img from "./img/view3.png";
import {ContextState} from "../../ContextState";


import {PAGE_BOOKING_HOME} from "../booking/routes";
import {UniversitySelect} from "../../form/select";

const Welcome = props => {

    const [view, setView] = React.useState('view-1');
    const [agreementChecked, setAgreementChecked] = React.useState(false);
    const [buttonLoading, setButtonLoading] = React.useState(false);
    const {dispatch, state: {user}} = React.useContext(ContextState);

    const [universityValue, setUniversityValue] = React.useState(user.university?user.university.id:undefined);
    const [universitySaving, setUniversitySaving] = React.useState(false);

    const router = useRouter();

    const goHome = () => {
        setButtonLoading(true);
        dataProvider('/api').update('user', {
            id: user.id,
            data: {
                welcomeShown: true,
            }

        }).finally(() => {
            setButtonLoading(false);
            router.pushPage(PAGE_BOOKING_HOME)
        })
    }

    const onUniversitySave = () => {
        setUniversitySaving(true);
        dataProvider('/api')
            .update('user', {
                id: user['id'],
                data: {
                    university: universityValue
                }
            })
            .then((resp) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        user: resp.data
                    }
                });
                setView('view-2');
            })
            .finally(() => {
                setUniversitySaving(false);
            });
    }


    return <Panel id={props.id}>
        {view === 'view-1' && <div className="view-1 view">
            <Div className="view-1__logo-wrap">
                <img src={logo} className="logo" alt="Студ Пространство"/>
                <p>Добро пожаловать!</p>
            </Div>
            <FormItem className="view-1__select" top="Выбери свое Студ.Пространство:">
                <UniversitySelect
                    initialOptions={user.university?[{
                        value: user.university.id,
                        label: user.university.name,
                    }]:[]}
                    onChange={(e) => setUniversityValue(e.target.value)}
                    value={universityValue}/>
            </FormItem>
            <Div className="view-1__project">
                <p className="title-2">Совместный проект</p>
                <img src={byImg} alt="Совместный проект"/>
            </Div>
            <Div className="view-1__button-wrap">
                <Button disabled={!universityValue} loading={universitySaving} size="l" stretched={true} onClick={onUniversitySave}>Далее</Button>
            </Div>
        </div>}
        {view === 'view-2' && <div className="view-2 view">
            <Div>
                <p className="title-2">Новые возможности прямо<br/>
                    в твоем ВУЗе!</p>
            </Div>
            <div className="view-2__img">
                <img src={ddelo} alt="Другое дело"/>
            </div>
            <Div>
                <p>Накапливай баллы в&nbsp;Студ.Пространстве и&nbsp;обменивай их&nbsp;на&nbsp;промокоды в&nbsp;сервисе «Другое Дело»</p>
            </Div>
            <Div className="view-2__button-wrap">
                <Checkbox
                    name="agreement"
                    value="1"
                    onClick={() => {
                        setAgreementChecked(!agreementChecked);
                    }}
                    checked={agreementChecked}
                >
                    Я согласен на создание профиля в сервисе
                    в «Другое Дело»
                </Checkbox>

                <Button size="l" stretched disabled={!agreementChecked} onClick={() => {
                    setView('view-3')
                }}>Далее</Button>
            </Div>
        </div>}
        {view === 'view-3' && <div className="view-3 view">
            <Div>
                <p className="title-2">Последние формальности</p>
            </Div>
            <div className="view-3__img">
                <img src={view3Img} alt=""/>
                <Div>
                    <p>Нажимая кнопку "Далее" ты подтверждаешь согласие с нашей <a href="https://vk.com/legal">политикой обработки персональных данных</a> и разрешаешь доступ к своему профилю
                        ВКонтакте</p>
                </Div>
            </div>

            <Div className="view-3__button-wrap">
                <Button size="l" stretched onClick={goHome} loading={buttonLoading}>Далее</Button>
            </Div>

        </div>}

    </Panel>
};

Welcome.propTypes = {
    id: PropTypes.string.isRequired,
};

export default Welcome;
