import React from 'react';

import {
    Panel, PanelHeader, CardGrid, Card, Cell, Spinner, Avatar, HorizontalScroll, HorizontalCell, Group, Button, Div, ModalPageHeader, Snackbar
} from '@vkontakte/vkui';
import {useRouter} from '@happysanta/router';
import plural from 'plural-ru';

import "./style.scss";
import {Icon16Done, Icon16InfoCircle, Icon24ClockOutline, Icon28MessageOutline} from "@vkontakte/icons";
import {PAGE_BOOKING_CREATE, PAGE_BOOKING_START} from "./routes";
import {ContextState} from "../../ContextState";
import {extendMoment} from "moment-range";
import Moment from 'moment';
import 'moment/dist/locale/ru';
import {PAGE_PROFILE} from "../profile/routes";
import dataProvider from "../../data-provider";
import {useSpaces} from "../../hooks/useSpaces";


const BookingHome = ({id, showModal}) => {
    const moment = extendMoment(Moment);
    moment.locale('ru');

    const router = useRouter();
    const {dispatch, state: {user: {university, ddRegistered}, refreshBookings = true, bookings = {list: [], page: 1}}} = React.useContext(ContextState);
    const [bookingLoading, setBookingLoading] = React.useState(false);
    const [bookingButtonLoading, setBookingButtonLoading] = React.useState(false);
    const [cancelButtonLoading, setCancelButtonLoading] = React.useState(null);
    const [bookingButtonHidden, setBookingButtonHidden] = React.useState(true);
    const [abortController, setAbortController] = React.useState();
    const [snackbar, setSnackbar] = React.useState(null);
    const {findSpaceName} = useSpaces();

    const {list: bookingList, page: bookingPage} = bookings;

    let isMounted = true;

    React.useEffect(() => {
        if (!ddRegistered) {
            dataProvider('/api').fetch('fetch/register', {
                method : 'post'
            })
        }
    }, []);

    React.useEffect(() => {

        if (!bookingList.length || refreshBookings) {

            setBookingLoading(true)
            fetchBooking(bookingPage)
                .finally(() => {
                    setBookingLoading(false)
                    isMounted && dispatch({
                        type: 'setState',
                        payload: {
                            refreshBookings: false
                        }
                    })
                });
        }


        return () => {
            isMounted = false;
            abortController && abortController.abort();
        }
    }, [refreshBookings])

    const fetchBooking = (page = 1) => {

        const _controller = new AbortController();
        setAbortController(_controller);

        return dataProvider('/api')
            .getList('booking', {
                _where: {
                    status: {
                        operator: 'in', value: [0, 1, 3]
                    }, date: {
                        operator: 'gte', value: Moment().format('YYYY-MM-DD')
                    }
                }, sort: {field: 'date', order: 'asc'}, pagination: {perPage: 3, page}, parameters: {signal: _controller.signal}
            })
            .then((json) => {
                isMounted && dispatch({
                    type: 'setState', payload: {
                        bookings: {
                            list: [...(page === 1) ? [] : bookingList, ...json.data], page: page
                        }
                    }
                });
                setBookingButtonHidden(!(json.total > bookingList.length + json.data.length))
                return json;
            })
    }

    const fetchMoreBooking = () => {
        setBookingButtonLoading(true);
        fetchBooking(bookingPage + 1)
            .then(json => {
                const hide = json.total <= bookingList.length + json.data.length;
                if (hide) {
                    setBookingButtonHidden(hide);
                    setSnackbar(<Snackbar
                        onClose={() => {
                            setSnackbar(null);
                        }}
                        before={<Avatar
                            size={24}
                            style={{background: "var(--vkui--color_background_accent)"}}
                        >
                            <Icon16InfoCircle fill="#fff" width={14} height={14}/>
                        </Avatar>}
                    >
                        Броней не найдено
                    </Snackbar>);
                } else {
                    setBookingButtonHidden(hide);
                }
            })
            .finally(() => {
                setBookingButtonLoading(false);
            });
    }

    const onBookCancelButtonClick = (ev) => {

        const bookId = parseInt(ev.currentTarget.dataset.id);

        setCancelButtonLoading(bookId)
        dataProvider()
            .delete('booking', {id: bookId})
            .then(({data}) => {
                data.id && dispatch({
                    type: 'setState', payload: {
                        bookings: {
                            list: bookingList.filter((b) => parseInt(b.id) !== parseInt(data.id)),
                        }
                    }
                });

                setSnackbar(<Snackbar
                    onClose={() => {
                        setSnackbar(null);
                    }}
                    before={<Avatar
                        size={24}
                        style={{background: "var(--vkui--color_background_accent)"}}
                    >
                        <Icon16Done fill="#fff" width={14} height={14}/>
                    </Avatar>}
                >
                    Запись успешно отменена
                </Snackbar>);

            })
            .finally(() => {
                setCancelButtonLoading(false);
            })
    }


    const formatHourRange = (book) => {
        const start = moment(`${book.date} ${book.startTime}`);
        const end = moment(`${book.date} ${book.endTime}`);
        const diff = end.diff(start, 'hours');
        return start.format('HH:mm') + '-' + end.format('HH:mm') + ' (' + diff + ' ' + plural(diff, 'час', 'часа', 'часов') + ')';
    }

    return <Panel id={id}>
        <PanelHeader separator={false}>
            Мои брони
        </PanelHeader>
        <CardGrid size="l" spaced={true} className="CardGrid__bookings">
            {bookingLoading && <Spinner size='large'/>}
            {!bookingLoading && !bookingList.length && <p>Предстоящих броней не найдено.</p>}
            {!bookingLoading && bookingList.length > 0 && bookingList.map((book, i) => <Card mode="shadow" key={"index-" + i}>
                <div className="card-content card-content__left">
                    <span className="card-content__date">{Moment(book.date).format('DD MMMM (dd)')}</span>
                    <span className="card-content__time">{formatHourRange(book)}</span>
                    <span className="card-content__space">{findSpaceName(book.space.id)}</span>
                </div>
                <div className="card-content card-content__right">
                    {[1, 3].indexOf(parseInt(book.status)) > -1 && Moment().isBetween(Moment(book.date + ' ' + moment(book.startTime).format('HH:mm')), Moment(book.date + ' ' + moment(book.endTime).format('HH:mm'))) && <>
                                    <span className="card-content__time-left"><Icon24ClockOutline/>
                                        {moment(book.endTime).diff(book.startTime, 'hours')} {plural(moment(book.endTime).diff(book.startTime, 'hours'), 'час', 'часа', 'часов')}
                                   </span>
                        <Button onClick={() => router.pushPage(PAGE_BOOKING_START, {id: book.id})} className="btn-start">Старт</Button>
                    </>

                    }
                    {parseInt(book.status) === 0 && Moment().isBetween(Moment(book.date + ' ' + moment(book.startTime).format('HH:mm')), Moment(book.date + ' ' + moment(book.endTime).format('HH:mm'))) && <>
                        <Button size="m" data-id={book.id} loading={!!cancelButtonLoading && cancelButtonLoading === parseInt(book.id)}
                                onClick={onBookCancelButtonClick} className="btn-cancel">Отмена</Button>
                    </>}
                    {!Moment().isBetween(Moment(book.date + ' ' + moment(book.startTime).format('HH:mm')), Moment(book.date + ' ' + moment(book.endTime).format('HH:mm'))) &&
                        <Button size="m" data-id={book.id} loading={!!cancelButtonLoading && cancelButtonLoading === parseInt(book.id)}
                                onClick={onBookCancelButtonClick} className="btn-cancel">Отмена</Button>}
                </div>
            </Card>)}

        </CardGrid>
        <Group className="action-group">
            {!bookingButtonHidden && <Div>
                <Button stretched size="l" mode="tertiary" loading={bookingButtonLoading} onClick={fetchMoreBooking}>Показать ещё</Button>
            </Div>}
            <Div>
                <Button size="l" onClick={() => router.pushPage(PAGE_BOOKING_CREATE)} stretched>Новая бронь</Button>
            </Div>
        </Group>

        <div className="about-stud">
            <Div>
                <h5 className="about-stud__title">Моё Студ.Пространство: {university.name}</h5>
                <p className="about-stud__working_hours">
                    {moment(university.workingStartDay, "E").format('dd').toUpperCase()}-{moment(university.workingEndDay, "E").format('dd').toUpperCase()}
                    &nbsp; с {('0' + university.visitingStartHour + '.00').substr(-5)} до {('0' + university.visitingEndHour + '.00').substr(-5)}
                    <br/>
                    {!!university.contacts && university.contacts.split(/[\n\r]+/).map((line, idx) => <span key={idx}>{line}<br/></span>)}
                </p>
            </Div>
            {university.gallery.length > 0 && <HorizontalScroll>
                <div className="about-stud__cells-wrapper">
                    {university.gallery.map((item, idx) => <HorizontalCell key={idx} size="l"><img src={`${item.previewUri}?size=400`}/></HorizontalCell>)}
                </div>
            </HorizontalScroll>}

            <Div>
                {university.description}
            </Div>
            <Cell before={!!university.contactAvatar && <Avatar src={university.contactAvatar.previewUri}/>}
                  after={!!university.contactLink && <Icon28MessageOutline onClick={() => window.open(university.contactLink, '_blank')}/>}
                  extraSubtitle={!!university.contactEmail && university.contactEmail}
                  subtitle={!!university.contactPhone && university.contactPhone}
                  className="about-stud__contact-cell"
            >
                {university.contactName}
            </Cell>
        </div>
        <Group className="btn-group">
            {!!university.rules && <Div>
                <Button size="l" stretched onClick={() => {
                    showModal(<Div>{university.rules}</Div>, {
                        id: 'rules-modal', header: <ModalPageHeader>Правила посещения</ModalPageHeader>, dynamicContentHeight: true
                    })
                }}>Правила посещения</Button>
            </Div>}
            <Div>
                <Button size="l" stretched onClick={() => router.pushPage(PAGE_PROFILE)}>Сменить Студ.Пространство</Button>
            </Div>
        </Group>
        {snackbar}
    </Panel>
};


export default BookingHome;
