import React from 'react';

import Moment from 'moment';
import 'moment/dist/locale/ru';
import {
    Div,
    Panel,
    PanelHeader,
    PanelHeaderBack,
    FormLayout,
    FormItem,
    Input,
    Group,
    VKCOM,
    usePlatform,
    Button,
    CardGrid,
    Card,
    File,
    Snackbar,
    Avatar
} from '@vkontakte/vkui';
import {useRouter, useParams} from '@happysanta/router';


import "./style.scss";
import {PAGE_BOOKING_CREATE, PAGE_BOOKING_HOME, PAGE_BOOKING_VERIFY} from "./routes";
import {ContextState} from "../../ContextState";
import {extendMoment} from "moment-range";
import {Icon28UserAddOutline} from "@vkontakte/icons";
import dataProvider from "../../data-provider";
import plural from "plural-ru";
import {useForm, Controller} from "react-hook-form";

const BookingVerify = ({id}) => {


        const {dispatch, state: {user: {profile = {}, ...user}, bookingRecord, refreshBookings}} = React.useContext(ContextState);
        const router = useRouter();

        if (!bookingRecord) {
            router.pushPage(PAGE_BOOKING_CREATE)
            return <></>
        }


        const [snackbar, setSnackbar] = React.useState(null);
        const [buttonLoading, setButtonLoading] = React.useState(false);

        const {control,  handleSubmit} = useForm({
            defaultValues: {
                name: user.first_name,
                surname: user.last_name,
                ...profile
            }
        });


        const platform = usePlatform();
        const {selectedPlace: space, selectedDate, startTime, endTime} = bookingRecord;
        const moment = extendMoment(Moment);
        moment.locale('ru');


        const onFormSubmit = (data) => {

            setButtonLoading(true);
            dataProvider()
                .update('user', {
                    id: user['id'],
                    data: {profile: data}
                })
                .then(({data}) => {
                    dataProvider('/api').create('booking', {
                        data: {
                            date: selectedDate,
                            startTime,
                            endTime,
                            space
                        }
                    }).then(() => {
                        setSnackbar(
                            <Snackbar
                                onClose={() => setSnackbar(null)}
                                before={
                                    <Avatar style={{fill: 'var(--accent)'}}>
                                        <Icon28UserAddOutline fill="inherit"/>
                                    </Avatar>
                                }
                            >
                                Урра! Твои данные были отправлены куратору Студ.Пространства на модерацию. Как только мы проверим их, тебе придет оповещение.
                            </Snackbar>
                        );
                        dispatch({
                            type: 'setState',
                            payload: {
                                refreshBookings: refreshBookings + 1,
                                bookingRecord: {},
                                data
                            }
                        })
                        router.pushPage(PAGE_BOOKING_HOME)
                    }).finally(() => {
                        setButtonLoading(false);
                    })
                });
        }

        const diffStartEndTime = moment(endTime, 'HH:mm').diff(moment(startTime, 'HH:mm'), 'hours');

        return <Panel id={id}>
            <PanelHeader separator={false}
                         before={
                             <PanelHeaderBack
                                 onClick={() => router.pushPage(PAGE_BOOKING_CREATE)}
                                 label={platform === VKCOM ? "Назад" : undefined}
                             />
                         }
            >
                Верификация
            </PanelHeader>

            <CardGrid size="l">
                <Card mode="shadow" className="selected-card">
                    <div className="card-content card-content__left">
                        <span className="card-content__date">{moment(selectedDate).format('DD MMMM')} ({moment(selectedDate).format('ddd')})</span>
                        <span className="card-content__time">{diffStartEndTime} {plural(diffStartEndTime, 'час', 'часа', 'часов')}</span>
                        <span className="card-content__place">Рабочее место {!!space && space.space.name} </span>
                    </div>
                    <div className="card-content card-content__right">
                        Верификация
                    </div>
                </Card>
            </CardGrid>


            <FormLayout onSubmit={handleSubmit(onFormSubmit)}>
                <Div className="form-description">
                    Чтобы&nbsp;мы&nbsp;могли оформить на&nbsp;тебя пропуск в&nbsp;Студ.Пространство, нам&nbsp;необходимо тебя верифицировать. Эту&nbsp;процедуру
                    надо&nbsp;будет будет пройти только при&nbsp;первом бронировании, в&nbsp;дальнейшем все&nbsp;будет быстро!
                </Div>
                {[{'surname': 'Фамилия'}, {'name': 'Имя'}, {'department': 'Факультет'}, {'group': 'Группа'}].map((o) => {
                    const [fieldName, fieldLabel] = Object.entries(o)[0];
                    return <Controller
                        key={fieldName}
                        name={fieldName}
                        control={control}
                        rules={{required: 'Это поле обязетльно для заполнения'}}
                        render={({field: {ref, ...restField}, fieldState: {error, invalid}}) => {
                            return <FormItem
                                status={invalid ? "error" : ""}
                                bottom={error?.message}
                            >
                                <Input
                                    placeholder={fieldLabel}
                                    type="text"
                                    getRef={ref}
                                    {...restField}
                                />
                            </FormItem>
                        }}
                    />
                })}

                <Controller
                    name="phone"
                    control={control}
                    rules={{
                        required: 'Это поле обязетльно для заполнения',
                        pattern: {
                            value: /^\+[0-9]{10,12}$/,
                            message: 'Введите телефон в формате +71112223344'
                        },
                        setValueAs: (v) => v.replace(/[^\+\d]/, '')
                    }}

                    render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                        return <FormItem
                            status={invalid ? "error" : ""}
                            bottom={error?.message}
                        >
                            <Input
                                placeholder="Телефон"
                                type="tel"
                                getRef={ref}
                                {...restField}
                            />
                        </FormItem>
                    }}
                />

                <Group>

                    <Controller
                        name="photo"
                        control={control}
                        rules={{
                            required: 'Загрузите фото студенческого билета',
                        }}

                        render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                            return <>
                                <Div className="preview">
                                    {value && <img src={value.previewUri || URL.createObjectURL(value)}/>}
                                </Div>
                                <FormItem
                                    status={invalid ? "error" : ""}
                                    bottom={error?.message}
                                >
                                    <File size="l" stretched align="center" name="photo" accept="image/*"
                                          getRef={ref}
                                          {...restField}
                                          onChange={(ev) => restField.onChange(ev.target.files[0])}
                                    >
                                        Загрузить фото студбилета
                                    </File>
                                </FormItem>
                            </>
                        }}
                    />


                    <FormItem>
                        <Button size="l" stretched align="center" type="submit" loading={buttonLoading}>
                            Отправить на верификацию
                        </Button>
                    </FormItem>
                </Group>
            </FormLayout>

            {snackbar}
        </Panel>
    }
;


export default BookingVerify;
