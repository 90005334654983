import {Page} from "@happysanta/router";


export const PAGE_BOOKING_HOME = '/';
export const PANEL_BOOKING_HOME = 'panel_booking_home';

export const PAGE_BOOKING_START = '/booking/start/:id([0-9]+)';
export const PANEL_BOOKING_START = 'panel_booking_start';

export const PAGE_BOOKING_CREATE = '/booking/new';
export const PANEL_BOOKING_CREATE = 'panel_booking_new';

export const PAGE_BOOKING_VERIFY = '/booking/verify';
export const PANEL_BOOKING_VERIFY = 'panel_booking_verify';

const VIEW_EPIC = 'view_epic';

export const routes = {
    [PAGE_BOOKING_HOME]: new Page(PANEL_BOOKING_HOME, VIEW_EPIC),
    [PAGE_BOOKING_START]: new Page(PANEL_BOOKING_START, VIEW_EPIC),
    [PAGE_BOOKING_CREATE]: new Page(PANEL_BOOKING_CREATE, VIEW_EPIC),
    [PAGE_BOOKING_VERIFY]: new Page(PANEL_BOOKING_VERIFY, VIEW_EPIC),

};