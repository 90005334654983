import React from 'react';
import metaData from "../../version/metadata.json";
import {
    Panel,
    PanelHeader,
    Checkbox,
    Group,
    SimpleCell,
    CustomSelect,
    Button,
    ButtonGroup,
    CustomSelectOption,
    Text,
    Avatar,
    FormItem,
    FormLayoutGroup,
    Div,
    Alert,
    Title,
    Spinner, ModalPageHeader, Snackbar
} from '@vkontakte/vkui';
import {ContextState} from "../../ContextState";
import dataProvider from "../../data-provider";
import plural from 'plural-ru';
import bridge from '@vkontakte/vk-bridge';

import "./style.scss";
import UniversitySelect from "../../form/select/university";
import AppParams from "../../utils/AppParams";
import {PAGE_PROFILE_BUG_REPORT} from "./routes";
import {router} from "../../router";
import {Icon16Done} from "@vkontakte/icons";


const Profile = ({id, alert, showModal}) => {

    const {dispatch, state: {user, achievements = []}} = React.useContext(ContextState);
    const [interestValue, setInterestValue] = React.useState();
    const [achievementsList, setAchievementsList] = React.useState(achievements);
    const [notificationTypesList, setNotificationTypesList] = React.useState([]);
    const [achievementsListShownAll, setAchievementsListShownAll] = React.useState(false);
    const [userInterests, setUserInterests] = React.useState(user.interests);
    const [universityValue, setUniversityValue] = React.useState(user.university.id);
    const [universitySaving, setUniversitySaving] = React.useState(false);
    const [universitySavingDisabled, setUniversitySavingDisabled] = React.useState(true);
    const [pageLoading, setPageLoading] = React.useState(true);
    const [snackbar, setSnackbar] = React.useState(null);

    const userAchievements = user.achievements.map(item => item.achievement.id);

    React.useEffect(() => {
        dataProvider('/api').fetch('fetch/checkTasks', {
            method : 'post'
        })
    }, []);

    React.useEffect(() => {
        let mounted = true;

        Promise.all([
            dataProvider().getList('achievements').then(({data}) => Promise.resolve(data)),
            dataProvider().getList('notification-type', {sort: {field: 'position', order: 'asc'}}).then(({data}) => Promise.resolve(data))
        ]).then(([achievements, notifications]) => {
            mounted && dispatch({
                type: 'setState',
                payload: {
                    achievements
                }
            });
            mounted && setAchievementsList(achievements);

            mounted && dispatch({
                type: 'setState',
                payload: {
                    notificationTypes: notifications
                }
            });
            mounted && setNotificationTypesList(notifications);
            setPageLoading(false);

        });
        return () => {
            mounted = false;
        }
    }, [])

    const saveUserInterests = (interests) => {
        dataProvider()
            .update('user', {
                id: user['id'],
                data: {
                    interests : interests||[]
                }
            })
            .then((resp) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        user: resp.data
                    }
                });
                setUserInterests(resp.data.interests);
            });
    }

    const renderOption = ({onClick, option, ...restProps}) => {

        const onSelect = (ev) => {
            onClick(ev);

            if (!userInterests.find((o) => o.id === option.value)) {

                saveUserInterests(userInterests.map((v) => v.id).concat([option.value]));
            }
            setInterestValue(undefined);
        }

        return <CustomSelectOption {...restProps} onClick={onSelect} option={option}/>
    }

    const onUniversitySave = () => {
        setUniversitySaving(true);
        dataProvider()
            .update('user', {
                id: user['id'],
                data: {
                    university: universityValue
                }
            })
            .then((resp) => {
                dispatch({
                    type: 'setState',
                    payload: {
                        user: resp.data
                    }
                });
                setSnackbar(
                    <Snackbar
                        onClose={() => {
                            setSnackbar(null);
                        }}
                        before={
                            <Avatar
                                size={24}
                                style={{background: "var(--vkui--color_background_accent)"}}
                            >
                                <Icon16Done width={14} height={14}/>
                            </Avatar>
                        }
                    >
                        <p>Студ.Пространство успешно изменено.</p>
                    </Snackbar>
                );
            })
            .finally(() => {
                setUniversitySaving(false);
                setUniversitySavingDisabled(true);
            });
    }

    const onUserSubscriptionChange = (ev) => {
        const target = ev.currentTarget;
        const checked = target.checked;
        const id = target.dataset.value;
        new Promise((resolve, reject) => {
            if (checked) {
                bridge
                    .send("VKWebAppGetAuthToken", {"app_id": AppParams.vk_app_id, "scope": ""})
                    .then(({access_token}) => {
                        bridge.send('VKWebAppCallAPIMethod', {
                            method: 'apps.isNotificationsAllowed',
                            params: {
                                access_token,
                                user_id: user.id,
                                v: '5.131'
                            }
                        }).then(({response: {is_allowed}}) => {
                            if (is_allowed) {
                                resolve()
                            } else {
                                bridge.send("VKWebAppAllowNotifications")
                                    .then(() => resolve())
                                    .catch(() => reject())
                            }

                        }).catch((err) => {
                            reject()
                        });
                    }).catch((err) => {
                    reject()
                });


            } else {
                resolve();
            }
        }).then(() => {
            dataProvider()
                .update('user', {
                    id: user['id'],
                    data: {
                        notificationSubscriptions: (checked ? user.notificationSubscriptions.concat([id]) : user.notificationSubscriptions.filter(item => item !== parseInt(id)))
                    }
                })
                .then((resp) => {
                    dispatch({
                        type: 'setState',
                        payload: {
                            user: resp.data
                        }
                    });
                })
        }).catch(() => {
            target.checked = false;
        })


    }

    const openInterestDeletion = (ev) => {
        const target = ev.currentTarget;
        alert(
            <Alert
                actions={[
                    {
                        title: "Отмена",
                        autoclose: true,
                        mode: "cancel",
                    },
                    {
                        title: "Удалить",
                        autoclose: true,
                        mode: "destructive",
                        action: () => {
                            saveUserInterests(userInterests.filter((v) => v.id !== parseInt(target.dataset.value)));
                        },
                    },
                ]}
                actionsLayout="horizontal"
                onClose={() => alert(null)}
                header="Удаление интереса"
                text="Вы уверены, что хотите удалить этот интерес из вашего списка?"
            />
        );
    };


    return <Panel id={id}>

        <PanelHeader separator={false}>
            Мой профиль
        </PanelHeader>
        <SimpleCell
            className="profile-cell"
            subtitle={<span className="coin-badge">{user.score} {plural(user.score, 'балл', 'балла', 'баллов')}</span>}
            before={<Avatar src={user.photo}/>}>
            <Title level={4}>{user.first_name} {user.last_name}</Title>
        </SimpleCell>

        {pageLoading && <Spinner size='large'/>}

        {!pageLoading && <>
            <Div>
                <Title level={3}>Мои достижения:</Title>
                <div className="achievements-list">
                    {achievementsList.length > 0
                        && achievementsList.slice(0, achievementsListShownAll ? achievementsList.length : 6)
                            .map((item, idx) => {

                                const hasAchieve = userAchievements.filter(item => item.id || item).includes(item.id);

                                return <div key={idx} className={"avatar-wrap" + (hasAchieve ? ' achieved' : '')}>
                                    <Avatar size={95}
                                            title={item[hasAchieve ? 'postDescription' : 'preDescription']}
                                            alt={item.name}
                                            mode="image"
                                            onClick={() => {
                                                showModal(<Div>
                                                    <p>{item[hasAchieve ? 'postDescription' : 'preDescription']}</p>
                                                    <div className={`achievement-image ${(hasAchieve ? ' achieved' : '')}`}>
                                                        <img src={item.iconPath.previewUri||item.iconPath} alt={item.name} />
                                                    </div>
                                                </Div>, {
                                                    id: 'achievement-modal', header: <ModalPageHeader>{item.name}</ModalPageHeader>, dynamicContentHeight: true
                                                })
                                            }}
                                            src={item.iconPath.previewUri||item.iconPath}></Avatar>
                                </div>
                            })
                    }
                </div>
                <div style={{display: "flex", justifyContent: "center"}}>
                    <Button mode="tertiary" onClick={() => {
                        setAchievementsListShownAll((prevState) => !prevState)
                    }}>{!achievementsListShownAll ? 'Показать ещё' : 'Скрыть'}</Button>
                </div>
            </Div>

            <Group header={<Title level={3}>Уведомления:</Title>} className="notification-subscriptions">
                {notificationTypesList.map((item, idx) => <Checkbox
                    onChange={onUserSubscriptionChange} data-value={item.id}
                    key={idx} defaultChecked={user.notificationSubscriptions.includes(item.id)}>
                    {item.title}
                </Checkbox>)}
            </Group>
            <Group header={<Title level={3}>Мои интересы:</Title>}>
                <FormItem>
                    {userInterests.length ?
                        <ButtonGroup className="tags-button">{userInterests.map((option) => <Button
                            onClick={openInterestDeletion} mode="outline"
                            key={"key_" + option.id} data-value={option.id}>
                            {option.name}
                        </Button>)}</ButtonGroup> : ''}
                    <InterestSelect renderOption={renderOption} value={interestValue}/>
                </FormItem>
            </Group>
            <Group header={<Title level={3}>Мое Студ.Пространство:</Title>}>
                <FormLayoutGroup mode="horizontal">
                    <FormItem>
                        <UniversitySelect
                            initialOptions={[
                                {
                                    value: user.university.id,
                                    label: user.university.name,
                                }
                            ]}
                            onChange={(e) => {
                                setUniversityValue(e.target.value);
                                setUniversitySavingDisabled(false);
                            }}
                            value={universityValue}/>
                    </FormItem>
                    <FormItem style={{flexGrow: 0, flexBasis: "120px"}} align="right">
                        <Button loading={universitySaving} disabled={universitySaving || universitySavingDisabled} size="l" mode="tertiary" onClick={onUniversitySave}>Изменить</Button>
                    </FormItem>
                </FormLayoutGroup>
            </Group>
        </>}
        <Div className="version">
            Версия сборки: #{metaData.build}
        </Div>
        <Div>
            <Button size="l" stretched onClick={() => router.pushPage(PAGE_PROFILE_BUG_REPORT)}>Нашел ошибку? Сообщи нам!</Button>
        </Div>
        {snackbar}
    </Panel>
};


const InterestSelect = (props) => {

    const [remoteQuery, setRemoteQuery] = React.useState("");
    const [fetching, setFetching] = React.useState(false);
    const [fetchedOptions, setFetchedOptions] = React.useState([]);


    const memorized = React.useMemo(() => {
        return {fetchTimer: 0, abortController: 0};
    }, []);

    React.useEffect(() => {
        return () => {
            memorized.fetchTimer && clearTimeout(memorized.fetchTimer)
            memorized.abortController && memorized.abortController.abort();
        };
    }, []);


    const fetchRemoteInterests = () => {


        memorized.abortController && memorized.abortController.abort();
        memorized.abortController = new AbortController()

        setFetching(true);
        dataProvider('/api')
            .getList('interest', {
                sort: {field : 'name'},
                pagination: {perPage: 1000000},
                parameters: {signal: memorized.abortController.signal}
            })
            .finally(() => {
                setFetching(false);
            })
            .then((json) => {
                setFetchedOptions(json.data.map((o) => ({label: o.name, value: o.id})));
            });

    };

    const searchInterests = (e) => {
        const _remoteQuery = e.target.value;
        setRemoteQuery(_remoteQuery);
        if (_remoteQuery.length < 3) {
            setFetchedOptions([]);
            setFetching(false);
        } else {
            memorized.fetchTimer && clearTimeout(memorized.fetchTimer)
            memorized.fetchTimer = setTimeout(fetchRemoteInterests, 200);

        }
    };

    const renderDropdown = ({defaultDropdownContent}) => {
        if (remoteQuery.length < 3) {
            return (<Text
                style={{padding: 12, color: "var(--vkui--color_text_secondary)"}}
            >
                Нужно ввести хотя бы три символа
            </Text>);
        }
        return defaultDropdownContent;
    };


    return <CustomSelect
        {...props}
        options={fetchedOptions}
        searchable={true}
        placeholder="Введите интерес"
        //onInputChange={!fetching && searchInterests}
        onOpen={!fetching && fetchRemoteInterests}
        fetching={fetching}
        //renderDropdown={!fetching && renderDropdown}
    />;
}

export default Profile;
