import React, {useState, useEffect} from 'react';
import bridge from "@vkontakte/vk-bridge";
import dataProvider from "../data-provider";
import {ContextState} from "../ContextState";

export function useEventTypes() {

    const {dispatch, state: {eventTypes = [], eventTypesLoaded = false}} = React.useContext(ContextState);

    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const findData = (prop, value) => {
        return data.reduce((previousValue, currentValue) => {
            if (currentValue[prop] === value) {
                previousValue.push(currentValue)
            }

            return previousValue;
        }, [])
    }

    useEffect(() => {
        if (!eventTypesLoaded) {
            (async () => {
                try {
                    const res = await   dataProvider().getList('event-type', {sort: {field: 'position', order: 'asc'}});
                    dispatch({
                        type: 'setState',
                        payload: {
                            eventTypes: res.data,
                            eventTypesLoaded : true
                        }
                    });

                } catch (err) {
                    setError(err);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, []);

    return {loading, eventTypes, error, findEventTypes: findData};
}
