import React, {useState} from 'react';

import {
    Panel,
    PanelHeader,
    Group,
    Div,
    ScreenSpinner,
    ButtonGroup,
    Button,
    IconButton,
    FormLayout,
    FormItem,
    Input,
    Checkbox,
    CardGrid,
    Card,
    Avatar,
    Banner,
    Spacing
} from '@vkontakte/vkui';

import bridge from "@vkontakte/vk-bridge"
import {useRouter} from '@happysanta/router';
import {ContextState} from "../../ContextState";
import "./style.scss";
import {PAGE_COMMUNITY_CREATE} from "./routes";
import {Icon16SearchOutline, Icon28SlidersOutline} from "@vkontakte/icons";
import dataProvider from "../../data-provider";
import plural from "plural-ru";

const Community = ({id}) => {

    const {
        dispatch, state: {
            user, communityData = {communityList: [], page: 1}, communityFilterParams = {}
        }
    } = React.useContext(ContextState);


    const [filtersShown, setFiltersShown] = React.useState(false);
    const [filterParameters, setFilterParameters] = useState(communityFilterParams);
    const [communityFetching, setCommunityFetching] = React.useState(false);
    const [communityButtonLoading, setCommunityButtonLoading] = React.useState(false);
    const [communityButtonDisabled, setCommunityButtonDisabled] = React.useState(false);
    const [abortController, setAbortController] = React.useState();
    const [snackbar, setSnackbar] = React.useState(null);

    const {communityList = [], page = 1} = communityData
    const router = useRouter();

    let isMounted = true;

    React.useEffect(() => {

        if (communityList.length) {
            if (communityList.length < 10) {
                setCommunityButtonDisabled(true);
            }
            return;
        }

        setCommunityFetching(true);
        fetchCommunities(page)
            .finally(() => {
                setCommunityFetching(false);
            });

        return () => {
            isMounted = false;
            abortController && abortController.abort();
        };
    }, []);


    const fetchCommunities = (page = 1) => {

        const _controller = new AbortController();
        setAbortController(_controller);

        const {datesRange, ..._filterParams} = filterParameters;

        return dataProvider()
            .getList('community', {
                pagination: {perPage: 10, page}, _where: {
                    ..._filterParams,
                }, parameters: {signal: _controller.signal}
            })

            .then((json) => {

                if (json.data.length < 10) {
                    setCommunityButtonDisabled(true);
                }

                isMounted && dispatch({
                    type: 'setState', payload: {
                        communityData: {
                            communityList: [...(page === 1 ? [] : communityList), ...json.data], page
                        }
                    }
                });
            })

    }

    const fetchMoreCommunities = () => {
        setCommunityButtonLoading(true);
        fetchCommunities(page + 1).finally(() => {
            setCommunityButtonLoading(false);
        });
    }


    const onFilterApply = (ev) => {
        ev.preventDefault();
        setFiltersShown(false);
        setCommunityFetching(true)
        setCommunityButtonDisabled(false);

        fetchCommunities(1).finally(() => {
            setCommunityFetching(false);
        });
    }

    const onFilterParametersChange = (ev) => {

        const target = ev.currentTarget;
        const value = {};

        if (target.name === 'tags') {
            if (target.checked) {
                value[target.name] = user.interests.map(item => item.id);
            } else {
                value[target.name] = [];
            }


        } else if (target.type === 'checkbox') {
            if (target.checked) {
                value[target.name] = (filterParameters[target.name] || []).concat([target.value]);
            } else {
                value[target.name] = (filterParameters[target.name] || []).filter(item => item !== target.value);
            }
        } else {
            value[target.name] = target.value;
        }

        setFilterParameters({...filterParameters, ...value});
    }


    const onCommunityJoinClick = (ev) => {
        const {id} = ev.currentTarget.dataset;
        const record = communityList.find((item) => item.id === parseInt(id));
        if (record) {
            bridge.send('VKWebAppJoinGroup', {
                group_id: record.externalId
            })
                .then((data) => {
                    if (data.result) {
                        // Пользователь подписался на сообщество
                    }
                })
                .catch((error) => {
                    // Ошибка
                    console.log(error);
                });
        }
    }

    const onOpenGroupClick = (ev) => {
        window.open(ev.currentTarget.dataset.link, '_blank noopener noreferrer')
    }

    return <Panel id={id}>
        <PanelHeader separator={false}>
            Сообщества
        </PanelHeader>
        <Div>
            <ButtonGroup stretched style={{justifyContent: "flex-end"}}>
                <Button stretched size="l" onClick={() => router.pushPage(PAGE_COMMUNITY_CREATE)}>Добавить свое сообщество</Button>
                <IconButton onClick={() => {
                    setFiltersShown(!filtersShown);
                }}>
                    <Icon28SlidersOutline fill="#8C64D8"/>
                </IconButton>
            </ButtonGroup>
        </Div>
        <FormLayout className={"communities__filter-panel " + (filtersShown ? 'show' : '')} onSubmit={onFilterApply}>
            <FormItem>
                <Input before={<Icon16SearchOutline aria-hidden/>}
                       autoComplete="off"
                       placeholder="По ключевому слову" name="query"
                       defaultValue={filterParameters._query}
                       onChange={(ev) => {
                           setFilterParameters({...filterParameters, _query: ev.currentTarget.value});
                       }}/>
            </FormItem>
            <FormItem>
                <Checkbox name="tags"
                          defaultChecked={filterParameters.tags && filterParameters.tags.length > 0}
                          onChange={onFilterParametersChange}>Учитывать мои интересы</Checkbox>
            </FormItem>
            <FormItem>
                <Button size="m" mode="outline" type="submit" stretched>Применить фильтр</Button>
            </FormItem>
        </FormLayout>
        <Group>
            {communityFetching && <ScreenSpinner size='large'/>}
            {!communityFetching && communityList.length > 0 && <><CardGrid size="l">{communityList.map((item) => <Card className="community-item"
                                                                                                                       key={"community_" + item.id}>
                <header>
                    <Avatar
                        size={48}
                        mode="image"
                        src={item.logo.previewUri}
                    />
                    <div>
                        <h4>{item.title}</h4>
                        <div className="members-count">{item.membersCount} {plural(item.membersCount, 'участник', 'участника', 'участников')}</div>
                        {item.tags.length > 0 && <ul className="tag-badges">{item.tags.map((tag) => <li key={"tag_" + tag.id}>{tag.name}</li>)}</ul>}
                    </div>
                </header>
                <div className="community-item__content" style={{whiteSpace: "pre-wrap"}}>
                    {item.description}
                </div>
                <footer>
                    <Spacing size={16} separator={false}/>
                    <ButtonGroup gap="m">
                        <Button size="s" data-link={item.link} onClick={onOpenGroupClick}>
                            Перейти
                        </Button>
                        <Button size="s" onClick={onCommunityJoinClick} data-id={item.id}>
                            Вступить
                        </Button>
                    </ButtonGroup>
                </footer>
            </Card>)}
            </CardGrid>
                {!communityButtonDisabled && <Div>
                    <Button size="l" disabled={communityButtonDisabled} loading={communityButtonLoading} onClick={fetchMoreCommunities} stretched>Показать
                        ещё</Button>
                </Div>}


            </>}

            {!communityFetching && communityList.length === 0 && <Banner header="Не найдено" mode="tint"
                                                                         text="По заданным параметрам записей не найдено."
                                                                         actions={<Button mode="outline" appearance="neutral" size="s"
                                                                                          onClick={() => setFiltersShown(true)}>
                                                                             Изменить параметры
                                                                         </Button>}>

            </Banner>}

        </Group>
        {snackbar}
    </Panel>
};


export default Community;
