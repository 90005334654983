import React from 'react';

import {
    Panel,
    PanelHeader,
    Div,
    Button,
    DateInput,
    FormLayoutGroup,
    FormLayout,
    FormItem,
    Textarea,
    ButtonGroup,
    Input,
    Avatar,
    Checkbox,
    PanelHeaderBack,
    VKCOM,
    usePlatform,
    withAdaptivity,
    Snackbar,
    File,
    SelectMimicry,
    CardGrid,
    Card,
    Gallery,
    Spacing,
    Counter,
    ModalPageHeader,
    IconButton
} from '@vkontakte/vkui';
import {ChipsSelect} from "@vkontakte/vkui/dist/unstable";
import {Icon16Done, Icon20InfoCircleOutline, Icon24Dropdown, Icon24Cancel} from "@vkontakte/icons";
import {useRouter} from '@happysanta/router';
import {IMaskMixin} from 'react-imask';

import {ContextState} from "../../ContextState";
import "./style.scss";
import {PAGE_EVENT_HOME} from "./routes";
import {useEventTypes} from "../../hooks/useEventTypes";
import {useInterests} from "../../hooks/useInterests";
import dataProvider from "../../data-provider";
import {useForm, Controller} from "react-hook-form";

const EventCreate = ({id, viewWidth, showModal}) => {

    const {state: {user, studSpaces}, dispatch} = React.useContext(ContextState);
    const [eventFormSaving, setEventFormSaving] = React.useState(false);
    const [snackbar, setSnackbar] = React.useState(null);
    const [activePanel, setActivePanel] = React.useState(id);
    const [selectedPlace, setSelectedPlace] = React.useState();

    const router = useRouter();
    const platform = usePlatform();
    const {eventTypes: eventTypeList} = useEventTypes();
    const {interests: interestList} = useInterests();
    const {control, handleSubmit, getValues, setError} = useForm({
        defaultValues: {
            types: [],
            owner: user.id,
            phone: user.phone,
        }
    });

    React.useEffect(() => {
        let isMounted = true;

        dataProvider('/api')
            .getList('stud-space')
            .then((json) => {

                isMounted && dispatch({
                    type: 'setState',
                    payload: {
                        studSpaces: json.data
                    }
                });
            });
        return () => {
            isMounted = false;
        }
    }, [user.university.id]);

    const PhoneInput = IMaskMixin(({inputRef, ...props}) => (
        <Input
            {...props}
            getRef={inputRef}  // bind internal input (if you use styled-components V4, use "ref" instead "innerRef")
            autoComplete="off"
        />
    ));

    const onFormSubmit = (formValues) => {
        const eventFormValues = {
            ...formValues,
            ...selectedPlace ? {space: selectedPlace.id} : {}
        }
        setEventFormSaving(true);

        (() => {
            if (eventFormValues.id) {
                return dataProvider().update('event', {
                    id: eventFormValues.id,
                    data: eventFormValues
                })
            } else {
                return dataProvider().create('event', {
                    data: eventFormValues
                })
            }

        })().then(({data}) => {
            setSnackbar(
                <Snackbar
                    onClose={() => {
                        setSnackbar(null);
                        !eventFormValues.id && router.pushPage(PAGE_EVENT_HOME)
                    }}
                    before={
                        <Avatar
                            size={24}
                            style={{background: "var(--vkui--color_background_accent)"}}
                        >
                            <Icon16Done fill="#fff" width={14} height={14}/>
                        </Avatar>
                    }
                >
                    Событие успешно отправлено на модерацию
                </Snackbar>
            );

        }).finally(() => {
            setEventFormSaving(false);
        }).catch(({cause}) => {
            const {errors} = cause;
            Object.keys(errors).forEach((field) => {
                setError(field, {
                    type: 'server',
                    message: Object.values(errors[field])[0]
                }, {
                    shouldFocus: true
                })
            })
        })
    }

    const showStudSpaceDetails = (studSpace) => {

        const {gallery, space, equipments} = studSpace;

        showModal(<Div className="stud-space__modal">
            {gallery.length > 0 && <Gallery slideWidth="90%">{gallery.map((item, idx) => <img
                key={idx}
                src={`${item.previewUri}?size=600`}
                style={{display: "block", maxWidth: '100%', height: 'auto'}}
                width={item.width}
                height={item.height}
            />)}</Gallery>}
            <Spacing/>
            <div className="equipments">{equipments.map((item, idx) => <Counter size="s" mode="secondary"
                                                                                key={idx}>{equipmentsRegistry[item.equipment.id]} ({item.count} шт.)</Counter>)}</div>
            <Spacing/>
            <div style={{display: 'flex', justifyContent: 'center'}}><Button align="center" onClick={() => {
                setActivePanel(id);
                setSelectedPlace(studSpace)
                showModal(undefined, {id: 'stud-space-modal'});
            }}>Выбрать это пространство</Button></div>
        </Div>, {
            size: "l",
            id: 'stud-space-modal',
            header: <ModalPageHeader>{space.name}</ModalPageHeader>,
            dynamicContentHeight: true,
        })
    }

    return (activePanel === id) ? <Panel id={id}>
        <PanelHeader separator={false} before={
            <PanelHeaderBack
                onClick={() => router.pushPage(PAGE_EVENT_HOME)}
                label={platform === VKCOM ? "Назад" : undefined}
            />
        }>
            Новое событие
        </PanelHeader>

        <FormLayout className="events-create" onSubmit={handleSubmit(onFormSubmit)}>
            <FormLayoutGroup mode={viewWidth <= 400 ? 'vertical' : 'horizontal'}>

                <Controller
                    name="dateStart"
                    control={control}
                    rules={{required: 'Выберите дату начала'}}
                    render={({field: {ref, ...fieldProps}, fieldState: {error, invalid}}) => {
                        return <FormItem top="Дата начала"
                                         status={invalid ? "error" : ""}
                                         bottom={error?.message}
                        >
                            <DateInput enableTime={true} disablePast={true} {...fieldProps}/>
                        </FormItem>
                    }}
                />

                <Controller
                    name="dateEnd"
                    control={control}
                    rules={{required: 'Выберите дату окончания'}}
                    render={({field: {ref, ...fieldProps}, fieldState: {error, invalid}}) => {
                        return <FormItem top="Дата окончания"
                                         status={invalid ? "error" : ""}
                                         bottom={error?.message}
                        >
                            <DateInput enableTime={true} disablePast={true} {...fieldProps}/>
                        </FormItem>
                    }}
                />
            </FormLayoutGroup>

            {eventTypeList.length > 0 && <Controller
                name="types"
                control={control}
                rules={{required: 'Выберите тип события'}}
                render={({field: {ref, onChange, value = [], ...fieldProps}, fieldState: {error, invalid}}) => {
                    return <FormItem top="Тип события"
                                     status={invalid ? "error" : ""}
                                     bottom={error?.message}
                    >
                        <div className="checkbox-group">
                            {eventTypeList.map((item, idx) => {
                                    return <Checkbox key={idx}
                                                     value={item.id}
                                                     getRef={ref}
                                                     defaultChecked={value && value.includes(parseInt(item.id))}
                                                     onChange={(ev) => {
                                                         if (ev.currentTarget.checked) {
                                                             onChange(value.concat([item.id]));
                                                         } else {
                                                             onChange(value.filter(v => v !== item.id));
                                                         }

                                                     }}
                                                     {...fieldProps}
                                    >
                                        {item.title}
                                    </Checkbox>
                                }
                            )
                            }
                        </div>
                    </FormItem>
                }}
            />}
            <Controller
                name="space"
                control={control}
                render={({fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                        top="Пространство"
                    >
                        <SelectMimicry
                            placeholder="Без пространства"
                            onClick={() => {
                                setActivePanel('studSpaces');
                            }}
                            after={<ButtonGroup align="center" gap="none" mode="horizontal">
                                {selectedPlace && <IconButton onClick={(ev) => {
                                    ev.stopPropagation();
                                    setSelectedPlace(undefined);
                                }}><Icon24Cancel/></IconButton>}
                                <IconButton hasHover={false} hasActive={false}><Icon24Dropdown/></IconButton>
                            </ButtonGroup>}
                        >
                            {selectedPlace && selectedPlace.space.name}
                        </SelectMimicry>
                    </FormItem>
                }}
            />

            <Controller
                name="tags"
                control={control}
                rules={{required: 'Введите теги'}}
                render={({field: {ref, onChange, value = [], ...fieldProps}, fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                    >
                        <ChipsSelect
                            {...fieldProps}
                            value={interestList.filter(item => value.includes(item.id)).map(item => ({value: item.id, label: item.name}))}
                            placeholder="Выберите теги"
                            creatable={false}
                            onChange={v => onChange(v.map(({value}) => value))}
                            options={interestList.map(item => ({value: item.id, label: item.name}))}
                        />
                    </FormItem>
                }}
            />
            <Controller
                name="title"
                control={control}
                rules={{
                    required: 'Введите название мероприятия',
                    maxLength: 120
                }}
                render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                    >
                        <Input
                            placeholder="Название мероприятия"
                            type="text"
                            autoComplete="off"
                            getRef={ref}
                            maxLength={120}
                            {...restField}
                        />
                    </FormItem>
                }}
            />
            <Controller
                name="content"
                control={control}
                rules={{
                    required: 'Введите описание мероприятия',
                    maxLength: 600
                }}
                render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                    >
                        <Textarea
                            placeholder="Краткое описание мероприятия"
                            maxLength={600}
                            aria-multiline
                            {...restField}
                        />
                    </FormItem>
                }}
            />

            <Controller
                name="maxParticipantsCount"
                control={control}
                rules={{
                    required: 'Введите максимальное количество участников',
                }}
                render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                    >
                        <Input
                            placeholder="Mаксимальное количество участников"
                            type="number"
                            autoComplete="off"
                            getRef={ref}
                            {...restField}
                        />
                    </FormItem>
                }}
            />
            <Controller
                name="organizer"
                control={control}
                rules={{
                    required: 'Введите организатора мероприятия',
                    maxLength: 256
                }}
                render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                    >
                        <Input
                            placeholder="Организатор мероприятия"
                            type="text"
                            autoComplete="off"
                            maxLength={256}
                            getRef={ref}
                            {...restField}
                        />
                    </FormItem>
                }}
            />

            <Controller
                name="moderator"
                control={control}
                rules={{
                    required: 'Введите модератора мероприятия',
                    maxLength: 256
                }}
                render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                    >
                        <Input
                            placeholder="Модератор мероприятия"
                            type="text"
                            autoComplete="off"
                            maxLength={256}
                            getRef={ref}
                            {...restField}
                        />
                    </FormItem>
                }}
            />

            <Controller
                name="phone"
                control={control}
                rules={{
                    required: 'Введите контактный телефон',
                    pattern: {
                        value: /^\+7\([0-9]{3}\)[0-9\-]{9}$/,
                        message: 'Введите телефон в формате +7(000)000-00-00'
                    },
                }}
                render={({field: {ref, ...restField}, fieldState: {error, invalid}}) => {
                    return <FormItem
                        status={invalid ? "error" : ""}
                        bottom={error?.message}
                    >
                        <PhoneInput
                            placeholder="Контактный телефон"
                            type="tel"
                            autoComplete="off"
                            getRef={ref}
                            maxLength={16}
                            mask='+{7}(000)000-00-00'
                            {...restField}
                        />
                    </FormItem>
                }}
            />

            <Controller
                name="image"
                control={control}
                rules={{
                    required: 'Загрузите фото мероприятия',
                }}

                render={({field: {ref, value, ...restField}, fieldState: {error, invalid}}) => {
                    return <>
                        <Div className="preview">
                            {value && <img src={value.previewUri || URL.createObjectURL(value)}/>}
                        </Div>
                        <FormItem
                            status={invalid ? "error" : ""}
                            bottom={error?.message}
                        >
                            <File size="m" stretched align="center" name="image" accept="image/*"
                                  getRef={ref}
                                  {...restField}
                                  onChange={(ev) => restField.onChange(ev.target.files[0])}
                            >
                                Загрузить фото мероприятия
                            </File>
                        </FormItem>
                    </>
                }}
            />

            <FormItem>
                <Button size="l" type="submit" loading={eventFormSaving} stretched>Отправить на модерацию</Button>
            </FormItem>
        </FormLayout>
        {snackbar}
    </Panel> : <Panel id={id}>
        <PanelHeader separator={false}
                     before={<PanelHeaderBack
                         onClick={() => setActivePanel(id)}
                         label={platform === VKCOM ? "Назад" : undefined}
                     />}
        >
            Пространство
        </PanelHeader>

        <CardGrid size="l" spaced={true} className="CardGrid__space-select">
            {studSpaces.map((studSpace, i) => <Card key={"index-" + i}>
                {studSpace.gallery.length ? <div className="space-image">
                    <img src={studSpace.gallery[0].previewUri}
                         width={studSpace.gallery[0].width}
                         height={studSpace.gallery[0].height}/>
                </div> : (studSpace.space?.photo ? <div className="space-image">
                    <img src={studSpace.space.photo.previewUri}
                         width={studSpace.space.photo.width}
                         height={studSpace.space.photo.height}/>
                </div> : null)}
                <div className="space-body">
                    <h5 className="name">{studSpace.space.name}</h5>
                    {studSpace.space.subtitle && <p>{studSpace.space.subtitle}</p>}
                    <p>Максимальное количество мест: {studSpace.capacity}</p>
                    <Button onClick={() => {
                        setActivePanel(id);
                        setSelectedPlace(studSpace)
                    }}>Выбрать</Button>
                </div>
                <div className="space-end">
                    <Icon20InfoCircleOutline onClick={() => showStudSpaceDetails(studSpace)}/>
                </div>
            </Card>)}
        </CardGrid>
    </Panel>
};


export default withAdaptivity(EventCreate, {viewWidth: true});
